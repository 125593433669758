<template>
    <div class="menu-actions-compontent">
        <div class="actions-list">
            <template v-for="(item,index) in actionList">
                <el-divider direction="vertical" :key="'l-'+index" v-if="item.split && item.positions.indexOf(position)>-1"></el-divider>
                <div class="item" :key="index" v-if="item.positions.indexOf(position)>-1" @mousedown.prevent @click="itemClick(item.key)">
                    <el-tooltip :content="item.tip" placement="top">
                        <!-- <el-button type="text" :icon="item.icon" >{{item.tip}}</el-button>  -->
                        <svg-icon :name="item.icon"></svg-icon>
                    </el-tooltip>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
export default {
    props:{
        position:{ //source = 原文，target = 译文，all = 全部
            type:String,
            default:'all'
        }
    },
    data(){
        return {
            x:'40%',
            y:'-34px',
            actionList:[
                {key:'term',tip:'添加术语',icon:'ib-jargon',positions:['all','source','target']},
                {key:'searchMemory',tip:'搜索记忆库',icon:'ib-search-tm',positions:['all','source','target']},
                // {key:'addComment',tip:'批注',icon:'ib-comment-o',positions:['all','source','target']},
                {key:'bold',tip:'加粗',icon:'ib-font-bold',positions:['all','target','source'],split:true},
                {key:'italic',tip:'斜体',icon:'ib-font-italic',positions:['all','target','source']},
                {key:'underline',tip:'下划线',icon:'ib-font-underline',positions:['all','target','source']},
                // {key:'strikeThrough',tip:'删除线',icon:'ib-font-strikethrough',positions:['all','target','source']},
                {key:'superscript',tip:'上标',icon:'ib-font-super',positions:['all','target','source']},
                {key:'subscript',tip:'下标',icon:'ib-font-sub',positions:['all','target','source']},
            ]
        }
    },
    methods:{
        setPosition(x,y){
            this.x = x;
            this.y = y;
        },
        itemClick(key){
            this.$emit('click',key);
        }
    },
    
}
</script>
<style lang="scss" scoped>
.menu-actions-compontent{
    position: absolute;
    padding: 1px 8px;
    border-radius: 4px;
    background-color: #2C2C2E;
    border: 1px solid #2C2C2E;
    box-sizing: border-box;
    opacity: .95;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    left: 40%;
    bottom: -34px;
    // margin: 0 auto;
    z-index: 10;
    // opacity: 1;
    .actions-list{
        display: flex;
        align-items: center;
        .item{
            width: 30px;
            height: 30px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 4px;
            cursor: pointer;
            &:hover{
                background-color: #000;
                color: #00D959;
            }
        }
    }
}
</style>
<style lang="scss">
.menu-actions-compontent{
    .el-divider{
        background-color:#7C7A7A;
    }
}
</style>
