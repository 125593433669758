<template>
    <div class="editrecord-component">
         <div class="editrecord-panel">
            <div class="panel-title">
                <div class="title"><svg-icon name="ib-edit-record"></svg-icon> 编辑痕迹</div>
                <div class="download-btn" @click="refreshTrace">
                        <el-tooltip content="刷新" placement="left">
                            <svg-icon name="ib-refresh"></svg-icon>
                        </el-tooltip>
                    </div>
                <!-- <div class="download-btn">
                    <el-tooltip content="下载编辑痕迹" placement="left">
                        <span @click="handleDownloadTrace">
                            <svg-icon name="ib-download"></svg-icon>
                        </span>
                    </el-tooltip>
                 </div> -->
                    <div class="close" @click="closeQaPanel">
                        <el-tooltip content="关闭" placement="left">
                            <svg-icon name="ib-close"></svg-icon>
                        </el-tooltip>
                    </div>
            </div>
            <div class="editrecord-content">
                <div class="editrecord-list">
                    <template v-if="data.length > 0">
                        <div class="item" v-for="(item, index) in data" :key="index">
                           <div class="html-content" v-html="item.htmlString"></div>
                           <div class="info-content">
                                <div class="info-row">
                                    <span class="username">【{{item.operationPhase}}】</span>
                                    <span class="datetime">{{item.operationTime}}</span>
                                </div>
                           </div>
                        </div>
                    </template>
                    <div class="none-block" v-else>没有留下任何编辑痕迹呢~</div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
import { docApi } from '@/utils/api';
import { commonApi } from '@/utils/api';

export default {
    props: {
        doc: {
            type: Object,
            default: {},
        },
        sentPermission: {
            type: Boolean,
            default: false,
        },
        data:{
            type: Array,
            default: [],
        },
        sentId:{
            type:String,
            default:''
        },
    },
    data(){
        return {
            list: [],
            
            
        }
    },
    methods: {
        closeQaPanel(){
            this.$emit('close');
        },
        handleDownloadTrace() {
            // 构建下载编辑痕迹的请求参数
            let url = commonApi.batchCreateDownloadTask; 
            let postData = {
                title: this.doc.originalFilename, 
                metadataIdList: [this.doc.projectId], 
                metadataType: "TMS_PROJECT", 
                moreOptions: {
                exportFileType: "trace", 
                documentIdList:  [this.doc.docId],
                }
            };
            // 发起下载请求
            this.$ajax.post(url, postData)
                .then(res => {
                if (res.status === 200) {
                    this.$store.dispatch('download/createNotices', res.data.map(item => ({ ...item, show: true })));
                }
                })
                .finally(() => {
                
                });
    },
        refreshTrace() {
                if(this.sentId) {
                    this.data = [];
                let url = `${docApi.querySentenceEditTrace}?documentId=${this.doc.docId}&sentenceId=${this.sentId}`;
                this.$ajax.get(url).then((res) => {
                    if (res.status === 200) {
                        if(res.data.length){
                            this.data = res.data.map(item => {
                                return {
                                    htmlString: item.htmlString, 
                                    operationPhase: item.operationPhase,
                                    operationTime: item.operationTime
                                }
                            });
                        }
                    }
                }).finally(() => {
                
                });
                }
        }

    },
    mounted() {
       
    }
}
</script>
<style lang="scss" scoped>



.editrecord-component{
    height: 100%;
    width: 380px;
    overflow-y: hidden;
    .editrecord-panel{
        display: flex;
        flex-direction: column;
        height: 100%;
        .panel-title{
            margin: 0 20px;
        }
        .download-btn{
                margin-right: 20px;
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        .editrecord-content{
            flex: 1;
            position: relative;
            .editrecord-list{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                overflow-y: auto;
                padding: 10px 20px;
                .item {
                    background-color: #F8F8F8;
                    border-radius: 8px;
                    // border-bottom: 1px solid #F1F1F1;
                    box-sizing: border-box;
                    margin-bottom: 10px;
                    padding: 12px 15px;
                    display: flex;
                    flex-direction: column;
                    .html-content {
                        font-size: 14px; 
                        line-height: 20px;
                        margin-bottom: 10px; 
                        ::v-deep {
                            /* 删除线样式 */
                            .edit-trace-delete {
                                text-decoration: line-through;
                                color: #FE8E0B;
                            }
                            
                            /* 下划线样式 */
                            .edit-trace-insert {
                                text-decoration: underline;
                                color: #00B670;
                            }
                        }
                    }
                    .info-content {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 12px; 
                        color: #7C7C7C;
                    }
                }
            .username{
                font-weight:600;
            }
            .datetime{
                margin-left:10px;
                color: #808080;
            }
        }
            .none-block{
               position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                text-align: center;
                font-size: 16px;
            }

        }
    }
    
}
</style>
