<template>
    <div class="preview-word-component">
        <template v-if="preview">
            <div class="difference-tips">*预览格式可能和下载后的最终格式不同</div>
            <template v-for="(item,index) in preview">
                <template v-if="item.type==='paragraph'">
                    <p :lang="doc.sourceLang" :key="`paragraph-source-${tools.getRandomId(6)}-${index}`" :style="item.style" v-if="['all','source'].indexOf(active) > -1">
                        <template v-for="(sent,sIndex) in item.sentences">
                            <span :key="`sent-source-${tools.getRandomId(6)}-${sIndex}`" 
                                :data-sentid="sent.sentId" 
                                :class="[sentId === sent.sentId?'active':'']" 
                                :style="{
                                    ...sent.style,
                                    fontSize:(sent.style&&(sent.style.fontSize*1<14?14:sent.style.fontSize))+'px',
                                    lineHeight:(sent.style&&(parseFloat(sent.style.fontSize<14?14:sent.style.fontSize)+lineHeightAddition))+'px',
                                    backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                }" 
                                v-html="sent.source"
                            ></span>
                        </template>
                    </p>
                    <p :lang="doc.targetLang" 
                        :key="`paragraph-target-${tools.getRandomId(6)}-${index}`" 
                        :style="{...item.style, textAlign:(doc.targetLang==='ar' && item.style.textAlign==='left')?'inherit':item.style.textAlign}" 
                        v-if="['all','target'].indexOf(active) > -1">
                        <template v-for="(sent,sIndex) in item.sentences">
                            <span :key="`sent-target-${tools.getRandomId(6)}-${sIndex}`" 
                                :data-sentid="sent.sentId" 
                                :class="[sentId === sent.sentId?'active':'']" 
                                :style="{
                                    ...sent.style,
                                    fontSize: (sent.style && (sent.style.fontSize * 1 < 14 ? 14 : sent.style.fontSize)) + 'px',
                                    lineHeight: (sent.style && (parseFloat(sent.style.fontSize * 1 < 14 ? 14 : sent.style.fontSize) + lineHeightAddition)) + 'px',
                                    backgroundColor: (sent.status === 'confirm' ? '#CDF7E6' : '')
                                }" 
                                v-html="sent.target"
                            ></span>
                        </template>
                    </p>
                </template>
                <template v-else-if="item.type==='image'">
                    <p :key="`image-${tools.getRandomId(6)}-${index}`" class="image">
                        <img :src="item.url ? item.url : ('data:image/jpg;base64,' + item.encode)" :style="`width:${item.width}px;height:${item.height}px;`"/>
                    </p>
                </template>
                <template v-else-if="item.type==='table'">
                    <nested-table :data="item" :active="active" :sentId="sentId" :doc="doc" :key="`table-${tools.getRandomId(6)}-${index}`"></nested-table>
                </template>
            </template>
        </template>
    </div>
</template>
<script>
import tools from '@/utils/tools';
import NestedTable from './NestedTable';
export default {
    name:'PreviewWord',
    components:{ NestedTable },
    props:{
        doc: {
            type: Object,
            default: {}
        },
        active:{
            type:String,
            default:'source'
        },
        preview:{
            type:Array,
            default:[]
        },
        sentId:{
            type:String,
            default:'',
        }

    },
    data(){
        return {
            lineHeightAddition:12,
            tools,
        }
    },
    methods:{

    },
    mounted(){

    }
}
</script>
<style lang="scss" scoped>
.preview-word-component{
    margin:20px 70px;
    min-height: 200px;
    word-wrap: break-word;
    .difference-tips{
        font-size: 12px;
        line-height: 17px;
        color: #999999;
        text-align: center;
        margin-bottom: 12px;
    }
    p{
        margin-bottom: 10px;
        // letter-spacing: 1.2px; 
        font-size: 14px;
        line-height: 26px;
        word-wrap: break-word;
        word-break: normal;
        > span{
            position: relative;
            border-bottom: 3px solid transparent;
            box-sizing: border-box;
            &:hover {
                // background-color: #7dd6b221;
            }
            &.active{
                border-bottom-color: #7DD6B3;
            }
        }
        &.image{
            text-align: center;
            > img{
                width: 90%;
            }
        }
    }
    .preview-table{
        width: 100%;
        margin-bottom: 15px;
        > thead{
            > tr > th{
                border: 1px solid #0000001a;
            }
        }
        > tbody{
            > tr > td{
                padding: 10px;
                border: 1px solid #0000001a;
                > p{
                    margin-bottom: 0;
                }
            }
        }
    }
}
</style>
