<template>
    <div class="tools-component" v-if="doc && doc.permission">
        <div class="title-content">
            <div class="gohome-content">
                <div class="goto-home" >
                    <svg-icon name="ie-lang-x" style="width: 30px;height: 30px;"></svg-icon>
                </div>
            </div>
            <page-title :title="doc.originalFilename" :flex="flex" style="padding-right:40px;flex:1;">
                <template slot="info" >
                    <div class="more-info">
                        <el-popover trigger="hover">
                            <el-form label-width="80px" size="mini" class="more-info-popover">
                                <el-form-item label="语言方向" v-if="doc.sourceLang">
                                    {{LANGUAGE_DICT[doc.sourceLang].name}} <i class="el-icon-arrow-right"></i> {{LANGUAGE_DICT[doc.targetLang].name}}
                                </el-form-item>
                                <!-- <el-form-item label="原文字符">{{doc.sourceWords}}</el-form-item> -->
                            </el-form>
                            <svg-icon name="ib-info" class-name="color-555" slot="reference" style="font-size:20px;"></svg-icon>
                        </el-popover>
                    </div>
                    <div style="margin-left:10px;display:flex;align-items: center;" v-if="doc.engineInfo">
                        <svg-icon :name="'ie-'+doc.engineInfo.icon" style="width: 16px;height: 16px;"></svg-icon>
                        <span style="margin-left:3px;font-size: 12px;line-height: 17px;color: #00000066;">{{doc.engineInfo.name}}提供基础翻译</span>
                    </div>
                    <div style="margin-left:10px;display:flex;align-items: center;" v-if="((doc.sourceLang === 'zh'&&doc.targetLang === 'en')||(doc.sourceLang === 'en'&&doc.targetLang === 'zh'))">
                        <svg-icon name="ie-lang-x" style="width: 16px;height: 16px;"></svg-icon>
                        <span style="margin-left:3px;font-size: 12px;line-height: 17px;color: #00000066;">LanguageX提供交互式翻译</span>
                    </div>
                    <div class="status">
                        <template v-if="saving === true">
                            <i class="el-icon-loading"></i> 保存中...
                        </template>
                        <template v-else>
                            <i class="el-icon-circle-check"></i> 已保存
                        </template>
                    </div>
                </template>
                <template slot="tools">
                    <div class="cursor-pointer" style="width:120px;" @click="showTaskList" v-if="doc.progress">
                        <div class="fonts-12 margin-b-5">{{doc.progress.typeName}}进度：{{doc.progress.value}}%</div>
                        <el-progress :color="progressColor" :format="progressFormat" :show-text="false" :stroke-width="10" :percentage="doc.progress.value * 1"></el-progress>
                    </div>
                    <div class="editor-mode-select margin-l-10" v-if="doc.permission.changeInteractiveMode">
                        <el-select :value="doc.interactiveMode||'IMT'" placeholder="切换编辑模式" size="mini" style="width:110px;" @change="changeInteractiveModeEvent">
                          <el-option label="交互式翻译" value="IMT" key="IMT"></el-option>
                          <el-option label="译后编辑" value="PE" key="PE"></el-option>
                        </el-select>
                    </div>
                    <template v-if="userInfo.organizationType === 'ENTERPRISE'">
                        <template v-if="doc.permission.submitTask">
                            <el-button class="margin-l-10" type="green" size="mini" round @click="submitTaskCheck" :loading="submitTaskBtnLoading">提交</el-button>
                        </template>
                        <template v-if="doc.permission.rollbackTask">
                            <el-button class="margin-l-10" type="danger" size="mini" round  @click="rejectBtnEvent">退回</el-button>
                        </template>
                    </template>
                    <div class="download-btn margin-l-10" v-if="doc.permission.download">
                        <el-dropdown  @command="handleDownloadCommand">
                            <el-button type="primary" round size="mini">
                                下载 <i class="el-icon-arrow-down el-icon--right"></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="target">译文</el-dropdown-item>
                                <el-dropdown-item command="bilingual" v-if="['xliff','xlf','sdlxliff','sdlxlf'].indexOf(doc.fileType)<0">
                                    双语 <pro-icon :size="14" style="margin-bottom:-2px;margin-left:5px;" v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1"></pro-icon>
                                </el-dropdown-item>
                                <el-dropdown-item command="source">原文</el-dropdown-item>
                                <el-dropdown-item command="tmx">TMX</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </page-title>
        </div>
        <div class="action-content">
            <div class="left">
                <div class="action-btn" @click="saveDocuments" :disabled="loadingKey === 'save' || !doc.permission.save"><svg-icon name="ib-save"></svg-icon>保存</div>
                <div class="action-btn" @click="repealBtnEvent" :disabled="loadingKey === 'repeal' || !doc.permission.revoke"><svg-icon name="ib-repeal"></svg-icon>撤销</div>
                <div class="action-btn" @click="recoverBtnEvent" :disabled="loadingKey === 'recover' || !doc.permission.recover"><svg-icon name="ib-recover"></svg-icon>恢复</div>
                <div class="action-btn" @click="searchAndReplacePanelShow" :disabled="!doc.permission.findAndReplace"><svg-icon name="ib-search"></svg-icon>查找替换</div>
                <div class="action-btn" @click="$emit('action','searchTMDb')" :disabled="!doc.permission.searchMemory"><svg-icon name="ib-search-tm"></svg-icon>搜索记忆库</div>
                <div class="action-btn" @click="jargonSettingEvent" :disabled="!doc.permission.customTerm">
                    <svg-icon name="ib-jargon"></svg-icon>术语定制
                    <template v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType) > -1">
                        <img alt="pro" src="../../../../static/images/pro-function-label.png" class="pro-function" />
                    </template>
                </div>
                <div class="action-btn" @click="$emit('action','fillTag')" :disabled="!doc.permission.fillTag">
                    <svg-icon name="ib-tag-o"></svg-icon>全文填充标签
                    <template v-if="['personal', 'pro-limited'].indexOf(userInfo.accountType) > -1">
                        <img alt="pro" src="../../../../static/images/pro-function-label.png" class="pro-function" />
                    </template>
                </div>
                <div class="action-btn" @click="showConfirmAlert('是否把所有句段改为已确认状态？空句段会自动填充机翻译文', quickSubmitEvent)"
                    @mousedown.prevent :disabled="loadingKey === 'quickSubmit' || !doc.permission.oneClickConfirm">
                    <svg-icon name="ib-check-circle"></svg-icon>一键确认
                </div>
                <el-divider direction="vertical" class="margin-x-5"></el-divider>
                <div class="action-btn" @click="$emit('action','splitSource')" :disabled="loadingKey === 'split' || !doc.permission.splitSentence"><svg-icon name="ib-split"></svg-icon>拆分</div>
                <div class="action-btn" @click="$emit('action','submitMarge')" :disabled="loadingKey === 'merge' || !doc.permission.mergeSentence" title="合并下一句到当前句"><svg-icon name="ib-merge"></svg-icon>合并</div>
                <el-divider direction="vertical" class="margin-x-5"></el-divider>

                <div class="action-btn" @click="$emit('action','editSource')" :disabled="loadingKey === 'editSource' || !sentPermission"><svg-icon name="ib-edit-source"></svg-icon>编辑原文</div>
                <div class="action-btn" @mousedown.prevent @click="$emit('action','copySourceToTarget')" :disabled="!sentPermission" title="将当前句段原文内容填充到译文"><svg-icon name="ib-copy"></svg-icon>复制原文</div>
                <div class="action-btn" @mousedown.prevent @click="$emit('action','copySourceTagToTarget')" :disabled="loadingKey === 'copySourceTagToTarget' || !sentPermission" title="将当前句段原文标签填充到译文"><svg-icon name="ib-copy-tag"></svg-icon>复制标签</div>
                <div class="action-btn" @mousedown.prevent @click="getMTResult" :disabled="loadingKey === 'getMt' || !sentPermission"><svg-icon name="ib-mt-o"></svg-icon>获取机翻</div>
                <div class="action-btn" @mousedown.prevent @click="$emit('action','lock')" :disabled="loadingKey === 'lock' || !sentPermission"><svg-icon name="ib-locked"></svg-icon>锁定</div>
                
                <div class="action-btn" @mousedown.prevent @click="setStyle('bold')" :disabled="!sentPermission"><svg-icon name="ib-font-bold"></svg-icon>加粗</div>
                <div class="action-btn" @mousedown.prevent @click="setStyle('italic')" :disabled="!sentPermission"><svg-icon name="ib-font-italic"></svg-icon>斜体</div>
                <div class="action-btn" @mousedown.prevent @click="setStyle('underline')" :disabled="!sentPermission"><svg-icon name="ib-font-underline"></svg-icon>下划线</div>
                <div class="action-btn" @mousedown.prevent @click="setStyle('superscript')" :disabled="!sentPermission"><svg-icon name="ib-font-super"></svg-icon>上标</div>
                <div class="action-btn" @mousedown.prevent @click="setStyle('subscript')" :disabled="!sentPermission"><svg-icon name="ib-font-sub"></svg-icon>下标</div>
                
                <div class="action-btn" @click="$emit('action','addComment')" :disabled="!sentPermission"><svg-icon name="ib-comment-o"></svg-icon>批注</div>
                
                

            </div>
            <div class="right">
                <div class="action-btn badge-style" @click="$emit('action', 'showFilter')">
                    <el-badge :value="filterSize" :hidden="filterSize === 0">
                        <svg-icon name="ib-funnel"></svg-icon>
                    </el-badge>
                筛选</div> 
                <div class="action-btn" @click="showQAPanel"><svg-icon name="ib-qa"></svg-icon>QA检查</div> 
                <div class="action-btn" @click="$emit('action', 'downloadStyleGuide')" v-if="userInfo.organizationType === 'ENTERPRISE'" title="下载风格指南"><svg-icon name="ib-download-file"></svg-icon>风格指南</div> 
                <el-dropdown @command="handleMoreFeatureDropdown">
                    <div class="action-btn" style="color: #000;"><svg-icon name="ib-more-circle"></svg-icon>更多设置</div> 
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item command="fontSetting"><div class="flex flex-align-center fonts-13"><svg-icon name="ib-font" className="fonts-18 margin-r-5"></svg-icon>字号</div></el-dropdown-item>
                        <el-dropdown-item command="hotkey"><div class="flex flex-align-center fonts-13"><svg-icon name="ib-keyboard" className="fonts-18 margin-r-5"></svg-icon>快捷键</div></el-dropdown-item>
                        <el-dropdown-item v-permission="`project:editRouter:editTrace`" command="editrecord"><div class="flex flex-align-center fonts-13"><svg-icon name="ib-edit-record" className="fonts-18 margin-r-5"></svg-icon>编辑痕迹</div></el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
                
                <el-divider direction="vertical"></el-divider>
                <div class="show-hide-btn" @click="$emit('action','setRightShow')">
                    <template v-if="showRight">
                        <el-tooltip content="收起" placement="bottom">
                            <svg-icon name="ib-show-right"></svg-icon><!-- 收起 -->
                        </el-tooltip>
                    </template>
                    <template v-else>
                        <el-tooltip content="展开" placement="bottom">
                            <svg-icon name="ib-show-left"></svg-icon><!-- 展开 -->
                        </el-tooltip>
                    </template>
                </div> 
            </div>
        </div>
        <alert-dialog ref="interactiveModeAlertDialogDom" 
            :message="alertInfo.message" 
            :type="alertInfo.type" 
            :buttonName="alertInfo.buttonName"
            :buttonType="alertInfo.buttonType"
            @confirmEvent="alertInfo.confirm">
        </alert-dialog>
        <jargon-dialog ref="jargonDialog" :doc="doc" :language="{source:doc.sourceLang,target:doc.targetLang}" :source="'editer'" @reTranslate="reTranslate"></jargon-dialog>
        <el-dialog title="任务列表" :visible.sync="taskDialog.show" destroy-on-close @open="initTaskDialog" @closed="taskDialog.list = []" :width="'520px'" top="20px">
            <el-table :data="taskDialog.list" v-loading="taskDialog.loading" style="width: 100%">
                <el-table-column prop="assignee.alias" label="负责人" width=""></el-table-column>
                <el-table-column prop="taskTypeDesc" label="任务类型" width="90"></el-table-column>
                <el-table-column label="进度" width="88">
                    <template slot-scope="scope">
                        <span class="color-1AC49C">{{scope.row.progress}}%</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="88">
                    <template slot-scope="scope">
                        <span class="status" :class="scope.row.status" style="font-size: 14px">{{scope.row.statusDesc}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="退回记录">
                    <template slot-scope="scope">被退回{{scope.row.reentries}}次</template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog title="退回任务" :visible.sync="rejectDialog.show" @closed="rejectDialogClosed" destroy-on-close width="430px" top="20px">
            <div class="text-center margin-b-10">任务将退回至<span class="text-weight-600 margin-x-5">{{rejectDialog.task.taskTypeDesc}}</span>阶段</div>
            <el-form>
                <el-form-item label="退回原因" required>
                    <el-input v-model="rejectDialog.reason" type="textarea" rows="3" placeholder="填写退回原因"></el-input>
                </el-form-item>
            </el-form>
            <div class="text-center" slot="footer">
                <el-button round plain size="small" @click="rejectDialog.show = false">取消</el-button>
                <el-button type="primary" round size="small" :loading="rejectDialog.loading" @click="submitRejectEvent">确定</el-button>
            </div>
        </el-dialog>
        <el-dialog title="字号" :visible.sync="fontSettingDialogShow" class="none-padding-body" destroy-on-close width="200px" top="20px">
            <div class="font-setting padding-y-15 padding-x-20">
                <div class="margin-b-10 color-919191 fonts-12">文字大小</div>
                <div class="flex flex-align-center">
                    <div class="fonts-13 margin-r-5">小</div>
                    <div class="flex-1"><el-slider v-model="fontValue" @change="fontValueChangeEvent" :step="25" :marks="marks" show-stops :show-tooltip="false" style="padding:0px 10px;"></el-slider></div>
                    <div style="font-size:17px;margin-left:5px;">大</div>
                </div>
            </div>
        </el-dialog>
        <el-dialog title="快捷键" :visible.sync="hotkeyDialogShow" class="none-padding-body" destroy-on-close width="280px" top="20px">
            <div class="padding-y-15 padding-x-20">
                <el-table :data="hotkeyList" size="small">
                    <el-table-column label="功能" prop="name"></el-table-column>
                    <el-table-column label="快捷键">
                        <template slot-scope="scope">
                            <div v-html="scope.row.key"></div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import $ from 'jquery';
import config from '@/utils/config';
import tools from '@/utils/tools'
import PageTitle from '@/components/PageTitle';
import JargonDialog from '../../../components/JargonDialog';
import AlertDialog from '@/components/AlertDialog';
import ProIcon from "@/components/icon/ProIcon";
import { projectApi, docApi, commonApi } from '@/utils/api';
import moment from 'moment';
export default {
    name:'Tools',
    props:{
        doc:{
            type:Object,
            default:null
        },
        saving:false,
        saveTime:{
            type:String,
            default:''
        },
        flex:{
            type:Number,
            default:1
        },
        splitMode:{
            type:Boolean,
            default:false,
        },
        editSource:{
            type:Boolean,
            default:false,
        },
        showRight:{
            type:Boolean,
            default: true,
        },
        filterSize:{
            type: Number,
            default: 0,
        },
        loadingKey: {
            type: String,
            default: 'save',
        },
        sentPermission: {
            type: Boolean,
            default: false,
        }
    },
    components:{ JargonDialog, PageTitle, AlertDialog, ProIcon },
    data(){
        return{
            alertInfo:{
                message:'切换回交互式翻译模式时，已确认句段会保留，未确认句段会清空，是否继续？',
                type:'confirm', //alert ,confirm
                buttonName:'确定',
                buttonType:'danger',
                confirm: ()=>{},
            },
            progressColor:'#7DD6B3',
            hotkeyList:[
                // {name:'撤销',key:'Ctrl + Z'},
                // {name:'恢复',key:'Ctrl + Y'},
                // {name:'术语定制',key:'Ctrl + T'},
                // {name:'查找替换',key:'Ctrl + H'},
                {name:'候选片段选择',key:'<kbd>→</kbd>'},
                {name:'整句应用机翻',key:'<kbd>Tab</kbd>'},
                {name:'译文确认',key:'<kbd>Enter</kbd>'},
                {name:'快速添加术语',key:'<kbd>Alt</kbd> + <kbd>T</kbd>'},
                {name:'搜索记忆库',key:'<kbd>Alt</kbd> + <kbd>F3</kbd>'},
            ],
            fontValue: 25,
            marks:{
                0: '',
                25: '',
                50: '',
                75: '',
                100: '',

            },
            searchAndReplaceDialogShow:false,
            fontSizelist:{0:'edit-font-13',25:'edit-font-14',50:'edit-font-15',75:'edit-font-16',100:'edit-font-17'},
            fontSizeIndex:1,
            searchAndReplace:{
                searchInputValue:'',
                replaceInputValue:'',
                matchList:[],
                currentMatch:null,
                currentMatchIndex:-1,
            },
            taskDialog: {
                show: false,
                loading: false,
                list: [],
            },
            submitTaskBtnLoading: false,
            // rejectTaskBtnLoading: false,
            rejectDialog: {
                show: false,
                loading: false,
                reason: '',
                task: {},
            },
            fontSettingDialogShow: false,
            hotkeyDialogShow: false,

        }
    },
    computed:{
        ...mapState({
            engineDist:state=>state.common.engineConfig,
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    methods:{
        progressFormat(percentage){
            return `译者翻译进度：${percentage}%`;
        },
        jargonSettingEvent(){
            if (['personal', 'pro-limited'].indexOf(this.userInfo.accountType) > -1){
                this.$message({
                    // showClose: true,
                    type: 'warning',
                    dangerouslyUseHTMLString: true,
                    message: 'Pro版专属功能，升级为Pro版可用。<a href="/account/vip">去升级</a>',
                });
                return false;
            }
            this.$refs.jargonDialog.setShow(true);
        },
        saveDocuments(){
            this.$emit('saveDocuments');
        },
        autoSaveSentences(index, status){
            this.$emit('saveSentences', index, status, true);
        },
        reTranslate(params){
            this.$emit('reTranslate',params);
        },
        fontValueChangeEvent(value){
            $('.editer-main').attr('class', function(i, c){
                return c.replace(/(^|\s)edit-font-\S+/g, '');
            });
            $('.editer-main').addClass(this.fontSizelist[value]);
        },
        addEditFontSize(){
            let _class = this.fontSizelist[this.fontSizeIndex];
            let _index = this.fontSizeIndex+1;
            if(_index > this.fontSizelist.length-1){
                _index = this.fontSizelist.length-1;
            }
            $('.editer-main').removeClass(_class).addClass(this.fontSizelist[_index]);
            this.fontSizeIndex = _index;
            if(_index === this.fontSizelist.length-1){
                this.$message.warning('已经是最大了');
            }
        },
        shrinkEditFontSize(){
            let _class = this.fontSizelist[this.fontSizeIndex];
            let _index = this.fontSizeIndex-1;
            if(_index < 0){
                _index = 0
            }
            $('.editer-main').removeClass(_class).addClass(this.fontSizelist[_index]);
            this.fontSizeIndex = _index;
            if(_index === 0){
                this.$message.warning('已经是最小了');
            }
        },
        getMTResult(){
            if(!this.doc.enableAiTrans){
                this.$message.error('人工翻译，不可获取机翻');
                return
            }
            let activeNode = $('.editer-main .item.active');
            if(activeNode.length > 0){
                let _index = activeNode.data("docindex");
                let _item = this.doc.sentences[_index];
                let node = activeNode.find('.target-doc .doc');
                let url = docApi.translationSentence;
                let postData={
                    sentId: _item.sentId,
                    domain:this.doc.domain,
                    engine:this.doc.engine,
                    engineKey: this.doc.engineInfo.key,
                    sourceLang:this.doc.sourceLang,
                    sourceText:_item.sourceText,
                    targetLang:this.doc.targetLang
                };
                if (this.doc.engine === 'robot'){
                    postData.modelId = this.doc.engineInfo.key.split('@')[1];
                }
                this.getMTResultBtnLoading = true;
                this.$ajax.post(url,postData).then(res=>{
                    if(res.status === 200){
                        let text = res.data.target || '';
                        this.doc.sentences[_index].targetText = text;
                        this.doc.sentences[_index].targetTaggedText = text;
                        node.html(text);
                        this.autoSaveSentences(_index, this.doc.sentences[_index].editorStatus);
                    }
                }).finally(()=>{
                    this.getMTResultBtnLoading = false;
                })
            }else{
                this.$message.warning('请选择句段');
            }
        },
        searchAndReplacePanelShow(){
            this.$emit('setFRPanel');
        },
        /**
         * 查找输入框输入文字1s后开始查找
         */
        searchCharInTranslationDoc(value){
            let self = this;
            if(this.searchCharTimer){
                clearTimeout(this.searchCharTimer);
            }
            this.searchCharTimer = setTimeout(()=>{
                self.searchCharInDoc(value);
            },1000);
        },
        /**
         * 主查找方法
         */
        searchCharInDoc(char){
            if(char.length > 0){
                this.resetSearchAndReplaceView();
                this.doc.sentences.map((item,index) => {
                    if(item.customText){
                        let text = item.customText.toLowerCase();
                        let charIndex = text.indexOf(this.searchAndReplace.searchInputValue.toLowerCase());
                        let num = 0;
                        while(charIndex !== -1){
                            this.searchAndReplace.matchList.push([index,num,charIndex,item.customText.substring(charIndex,charIndex + this.searchAndReplace.searchInputValue.length)]);
                            num ++;
                            charIndex = text.indexOf(this.searchAndReplace.searchInputValue.toLowerCase(),charIndex + 1);
                        }
                        if(text.indexOf(this.searchAndReplace.searchInputValue.toLowerCase()) !== -1){
                            this.setSearchAndReplaceView(this.searchAndReplace.searchInputValue,index,item.customText);
                        }
                    }
                });
                if(this.searchAndReplace.matchList.length > 0){
                    this.searchAndReplace.currentMatch = this.searchAndReplace.matchList[0];
                    this.searchAndReplace.currentMatchIndex = 0;
                    this.setSearchHighlightView();
                }
                
            }else{
                this.resetSearchAndReplaceView();
            }
        },
        /**
         * 设置查找到的文字UI
         */
        setSearchAndReplaceView(char,index,text){
            let doc = $('.editer-main .item .target-doc .doc')[index];
            let lowerText = text.toLowerCase();
            // let reg = new RegExp(char,'gi');
            let textList = lowerText.split(char.toLowerCase());
            let rst = textList[0];
            for(let k=1;k<textList.length;k++){
                let t = textList[k];
                let _char = char;
                for(let i=0;i<this.searchAndReplace.matchList.length;i++){
                    let item = this.searchAndReplace.matchList[i];
                    if(item[0] === index && item[1] === i){
                        _char = item[3];
                        break;
                    }
                }
                rst = rst + '<span class="search-match">'+_char+'</span>' + t;
            }
            $(doc).html(rst);
        },
        /**
         * 查找重置
         */
        resetSearchAndReplaceView(){
            this.searchAndReplace.matchList.map(item=>{
                let text = this.doc.sentences[item[0]].customText;
                let doc = $('.editer-main .item .target-doc .doc')[item[0]];
                $(doc).html(text);
            });
            this.searchAndReplace.matchList = [];
        },
        /**
         * 设置高亮
         */
        setSearchHighlightView(){
            $('.search-match').removeClass('active');
            let node = $('.search-match')[this.searchAndReplace.currentMatchIndex];
            $(node).addClass('active');
            this.$scrollTo(node,30,{
                container:$('.edit .left')[0],
                offset: -100,
            });
        },
        /**
         * 下一个
         */
        searchNextChar(){
            let index = this.searchAndReplace.currentMatchIndex + 1;
            if(this.searchAndReplace.matchList.length === 0){
                index = -1;
            }else if(index > this.searchAndReplace.matchList.length -1){
                index = 0;
            }
            this.searchAndReplace.currentMatchIndex = index;
            this.setSearchHighlightView();
        },
        /**
         * 上一个
         */
        searchPrevChar(){
            let index = this.searchAndReplace.currentMatchIndex - 1;
            if(index < 0){
                index = this.searchAndReplace.matchList.length - 1;
            }
            this.searchAndReplace.currentMatchIndex = index;
            this.setSearchHighlightView();
        },
        /**
         * 关闭查找替换弹窗
         */
        closesearchAndReplaceDialog(){
            this.resetSearchAndReplaceView();
            this.searchAndReplaceDialogShow = false;
            this.searchAndReplace.searchInputValue = '';
            this.searchAndReplace.currentMatchIndex = -1;
        },
        clearSearchInputValue(){
            this.resetSearchAndReplaceView();
            this.searchAndReplace.searchInputValue = '';
            this.searchAndReplace.currentMatchIndex = -1;
        },
        handleDownloadCommand(command){
            if(command === 'bilingual'){
                if(['personal', 'pro-limited'].indexOf(this.userInfo.accountType)>-1){
                    this.$router.push('/account/vip');
                    return;
                }
            }
            // let url = `${docApi.downloadDocumentFile}?id=${this.doc.docId}`;
            let url = commonApi.batchCreateDownloadTask;
            let postData = {
                title: this.doc.originalFilename,
                metadataIdList: [this.doc.docId],
                metadataType: "TMS_DOCUMENT",
                moreOptions: {
                    exportFileType: command,
                }
            };
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$store.dispatch('download/createNotices', res.data.map(item => { return { ...item, show: true} }));
                }
            }).finally(()=>{
                
            });
        },
        gotoHome(){
            this.$router.push('/');
        },
        showQAPanel(){
            this.$emit('setQAPanel');
        },
        repealBtnEvent(){
            this.$emit('repeal','repeal');
        },
        recoverBtnEvent(){
            this.$emit('recover','recover');
        },
        splitBtnEvent(){
            this.$emit('setSplitMode');
        },
        setStyle(type){
            this.$emit('setStyle', type);
        },
        changeInteractiveModeEvent(val){
            if(val === 'IMT'){
                // this.$refs.interactiveModeAlertDialogDom.setShow(true);
                this.showConfirmAlert('切换回交互式翻译模式时，已确认句段会保留，未确认句段会清空，是否继续？', this.confirmInteractiveModeEvent);
            }else if(val === 'PE'){
                this.$emit('action','changeEditorMode',val);
            }
        },
        confirmInteractiveModeEvent(){
            this.$emit('action','changeEditorMode','IMT');
        },
        quickSubmitEvent(){
            this.$emit('action','quickSubmit');
        },
        submitTaskCheck() {
            /* let url = `${docApi.checkTaskStatus}?taskId=${this.doc.myTask.taskId}`;
            this.submitTaskBtnLoading = true;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) {
                    this.showConfirmAlert('确定提交吗？', this.submitTaskEvent);
                }
            }).finally(() => {
                this.submitTaskBtnLoading = false;
            }); */
            this.showConfirmAlert('确定提交吗？', this.submitTaskEvent);
        },
        submitTaskEvent() {
            this.$emit('action','submitTask');
        },
        showConfirmAlert(message, confirm){
            this.alertInfo = {
                ...this.alertInfo,
                message,
                confirm,
            }
            this.$refs.interactiveModeAlertDialogDom.setShow(true);
        },
        async rejectBtnEvent(){
            if (!this.doc.lastTask) {
                this.$message.error("当前阶段不可退回");
                return;
            } 
            this.rejectDialog.task = this.doc.lastTask;
            this.rejectDialog.show = true;
        },
        submitRejectEvent() {
            if(!this.rejectDialog.reason) {
                this.$message.error('请填写退回原因');
                return;
            }
            let url = projectApi.submitRejectToPrev;
            let postData = {
                pipelineId: this.doc.myTask.pipelineId,
                // endTaskId: this.rejectDialog.task.taskId,
                reason: this.rejectDialog.reason,
            };
            this.rejectDialog.loading = true;
            this.$ajax.post(url, postData).then(async res => {
                if(res.status === 200) {
                    this.$message.success("退回成功");
                    this.rejectDialog.show = false;
                    this.$router.replace('/task/index');
                    // await tools.sleep(1000);
                    // location.reload(true);
                }
            }).finally(() => {
                this.rejectDialog.loading = false;
            })
        },
        rejectDialogClosed() {
            this.rejectDialog = this.$options.data().rejectDialog;
        },
        showTaskList() {
            // if (!this.doc.permission.manage) return;
            // this.taskDialog.show = true;
        },
        initTaskDialog () {
            this.taskDialog.loading = true;
            let url = `${docApi.queryFileTaskList}?documentId=${this.doc.docId}`;
            this.$ajax.get(url).then(res => {
                if(res.status === 200) { 
                    this.taskDialog.list = res.data;
                }
            }).finally(() => {
                this.taskDialog.loading = false;
            })
        },
        handleMoreFeatureDropdown(command) { //TODO 更多设置
            switch(command){
                case 'fontSetting':
                    this.fontSettingDialogShow = true;
                    break;
                case 'hotkey':
                    this.hotkeyDialogShow = true;
                    break;
                case 'editrecord':
                    this.$emit('action','editRecordShow');
                    break;
            }
        },
    },
    mounted(){
        
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/variables.scss';
.split-line{
    border-left:1px solid #999999;
    // width: 1px;
    height: 15px;
    margin: 0 10px;
}
.status {
    font-size: 12px;
    &.TO_START {
        color: #FE8E0B;
    }
    &.IN_PROGRESS {
        color: #2979FF;
    }
    &.COMPLETED,
    &.FINISHED {
        color: #00B670;
    }
}
.tools-component{
    > .title-content{
        // margin-top: 20px;
        display: flex;
        height: 60px;
        > .gohome-content{
            height: 100%;
            display: flex;
            box-sizing: border-box;
            align-items: center;
            border-bottom: 1px solid #0000001A;
            .goto-home{
                width: 30px;
                height: 30px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 4px;
                margin-right: 10px;
                margin-left: 20px;
                svg{
                    font-size: 30px;
                }
                img{
                    width: 30px;
                    display: none;
                }
            }
        }
         
        .status{
            color: #999999;
            font-size: 12px;
            // line-height: 17px;
            margin-left:20px;
            display:flex;
            align-items: center;
            > i{
                font-size: 16px;
                margin-right: 5px;
            }
        }
    }
    > .action-content{
        height: 60px;
        display: flex;
        padding: 0 10px 0 20px;
        background-color: #FAFAFA;
        box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.1);
        .action-btn{
            font-size: 12px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 8px 10px;
            border-radius: 8px;
            cursor: pointer;
            position: relative;
            &:hover{
                background-color: #0000001A;
            }
            &[disabled],
            &:disabled{
                opacity: .3;
                pointer-events: none;
                user-select: none;
            }
            svg {
                font-size: 16px;
                margin-bottom: 5px;
                color: #222;
            }
            .pro-function{
              position: absolute;
              top: 0;
              right: 0;
              widows: 22px;
              height: 22px;
            }
        }
        .show-hide-btn{
            cursor: pointer;
            font-size: 19px;
            padding: 8px 2px;
        }
        > .left{
            // flex: 4;
            display: flex;
            align-items: center;
        }
        > .right{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }
    }
    > .search-replace-wrapper{
        position: absolute;
        top: 140px;
        left: 40px;
        width: 480px;
        // height: 248px;
        background-color: #ffffff;
        border-radius: 8px;
        box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.06);
        z-index: 1000;
        > .dialog-title{
            height:56px;
            border-bottom:1px solid rgba(0, 0, 0, 0.05);
            display:flex;
            justify-content:center;
            align-items:center;
            > .title{
                flex: 1;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 56px;
                font-weight: 600;
                font-size: 16px;
                color: #000000;
            }
            > .close{
                width: 56px;
                height: 56px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 20px;
                cursor: pointer;
                &:hover{
                    color: $font-primary-color;
                }
            }
        }
        > .dialog-body{
            padding: 20px 30px;
            > .input-item{
                height: 40px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                display: flex;
                margin-bottom: 10px;
                > .input-label{
                    width: 76px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    color: #909399;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    > span{
                        margin-right: 8px;
                    }
                }
                > .input-edit{
                    flex: 1;
                }
                > .search-num{
                    // width: 30px;
                    margin-right: 10px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 13px;
                    color: rgba(0, 0, 0, 0.5);
                }
                > .clear-btn{
                    width: 24px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 15px;
                    cursor: pointer;
                    margin-right: 10px;
                }
            }
        }
        > .dialog-footer{
            display: flex;
            padding: 0 30px;
            margin-bottom: 20px;
            > div{
                flex: 1;
            }
            > .right{
                text-align: right;
            }
        }
    }
    .editor-mode-select{
        ::v-deep .el-input__inner{
            border-radius: 50px;
            color: #000000;
        }
    }
    .font-setting{
        $base-size: 10px;
        ::v-deep .el-slider {
            .el-slider__marks-text{
                min-width: 17px;
                font-size: 12px;
            }
            
            .el-slider__runway{
                height: $base-size;
                background-color: #E5E5E5;
            }
            .el-slider__bar {
                height: $base-size;
                background-color: transparent;
            }
            .el-slider__button-wrapper {
                height: $base-size + 6px;
                width: $base-size + 6px;
                top: -4px;
            }
            .el-slider__button {
                width: $base-size;
                height: $base-size;
                border: none;
                background-color:#7DD6B3;
            }
            .el-slider__stop {
                height: $base-size;
                width: $base-size;
                background-color: #CCCCCC;
            }
        }
        
    }
}
</style>
<style lang="scss">
@import '../../../assets/css/variables.scss';
.input-item{
    > .input-edit{
        .el-input__inner{
            border: none;
        }
    }
}
.tools-component{
    .title-content{
        .el-progress-bar__innerText{
            color: #000000;
        }
    }
    .el-divider--vertical{
        height: 1.5em;
    }
}
</style>

