<!-- 
    编辑器第三版 
    全新UI，添加了原文和译文实时预览功能
    author: jason.li
    time: 
-->
<template>
    <div class="basic-editer-component">
        <Affix relativeElementSelector=".preview-main">
            <div class="preview-type-list" >
                <template v-for="item in previewTypeList">
                    <div :class="['item', item.key === activePreview ? 'active':'']" 
                        :style="item.permiss === 'pro' && ['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1?'width:50px;':''"
                        :key="item.key" 
                        @click="previewTypeChange(item)">
                        {{item.name}}
                        <template v-if="item.permiss === 'pro' && ['personal', 'pro-limited'].indexOf(userInfo.accountType)>-1">
                            <svg-icon name="ic-pro" style="font-size:14px; margin-left:5px;"></svg-icon>
                        </template>
                    </div>
                </template>
            </div>
        </Affix>
        <div class="preview-main">
            <div class="preview-content"> <!-- ,'srt' -->
                <preview-word :doc="doc" :active="activePreview" :preview="preview" :sentId="sentId" v-if="['doc','docx','txt','rtf', 'srt', 'pdf'].indexOf(doc.fileType)>-1"></preview-word>
                <preview-excel :doc="doc" :active="activePreview" :preview="preview" :sentId="sentId" v-else-if="['xls','xlsx','csv'].indexOf(doc.fileType)>-1"></preview-excel>
                <preview-ppt :doc="doc" :active="activePreview" :preview="preview" :sentId="sentId" v-else-if="['ppt','pptx'].indexOf(doc.fileType)>-1"></preview-ppt>
                <div class="none-list-block" style="color:#888;font-size:14px;" v-else>
                    当前文档暂不支持预览
                </div>
            </div>
        </div>
        <div class="editer-main">
            <div class="editer-content" style="margin-bottom:50px;" v-if="doc.sentences.length > 0">
                <template v-for="(item,index) in doc.sentences">
                    <div :key="index" 
                        :data-docIndex="index" 
                        :data-lineNumber="(pagination.pageSize * (pagination.pageNumber - 1) + 1 + index)" 
                        :data-sentid="item.sentId"
                        class="item"
                        :class="{
                            'saved': item.editorStatus === 'confirm',
                            'active': activeItemIndex === index,
                            'disabled': (doc.permission && doc.permission.editable) && !item.permission.editable
                        }"
                        @click="e=>lineClickEvent(e,index,item)">
                        <transition name="menu-actions-right-slide" enter-active-class="animated fadeInDown faster"> 
                            <menu-actions ref="menuActionsNode" @click="menuBtnClickEvent" v-show="showMenuActions === item.sentId"></menu-actions>
                        </transition>
                        <div class="editer">
                            <div class="doc-content">
                                <div class="left">
                                    <div class="line-num">{{(pagination.pageSize * (pagination.pageNumber - 1) + 1 + index)}}</div>
                                    <div class="flex flex-justify-center margin-y-15">
                                        <div class="comment" v-if="item.repeated">
                                            <el-tooltip :content="`${getSentSourceText(item)}`" placement="top">
                                                <svg-icon name="ib-readonly" className="fonts-16"></svg-icon>
                                            </el-tooltip>
                                        </div>
                                        <div class="comment" v-else>
                                            <template v-if="item.fillMode === 'TM'">
                                                <el-tooltip :content="`${getSentSourceText(item)}`" placement="top">
                                                    <svg-icon name="ib-memory" className="fonts-16 color-link"></svg-icon>
                                                </el-tooltip>
                                            </template>
                                            <template v-else-if="item.fillMode === 'MT'">
                                                <el-tooltip :content="`${getSentSourceText(item)}`" placement="top">
                                                    <svg-icon name="ib-mt" className="fonts-16 color-warning"></svg-icon>
                                                </el-tooltip>
                                            </template>
                                            <template v-else-if="item.fillMode === 'NT'">
                                                <el-tooltip :content="`${getSentSourceText(item)}`" placement="top">
                                                    <svg-icon name="ib-readonly" className="fonts-16"></svg-icon>
                                                </el-tooltip>
                                            </template>
                                        </div>
                                        <div class="comment margin-l-5 cursor-pointer" v-if="item.locked !== null">
                                            <template v-if="item.permission.editable">
                                                <template v-if="item.locked">
                                                    <span @click="changeItemLockStatusEvent(index,false)">
                                                        <el-tooltip content="点击解锁" placement="top">
                                                            <svg-icon name="ib-locked" className="fonts-16"></svg-icon>
                                                        </el-tooltip>
                                                    </span>
                                                </template>
                                                <template v-else>
                                                    <span @click="changeItemLockStatusEvent(index,true)">
                                                        <el-tooltip content="点击锁定" placement="top">
                                                            <svg-icon name="ib-unlock" className="fonts-16"></svg-icon>
                                                        </el-tooltip>
                                                    </span>
                                                </template>
                                            </template>
                                            <template v-else>
                                                <svg-icon name="ib-locked" className="fonts-16" v-if="item.locked"></svg-icon>
                                                <svg-icon name="ib-unlock" className="fonts-16" v-else></svg-icon>
                                            </template>
                                        </div>
                                        <template v-if="item.commentCount">
                                            <div class="comment cursor-pointer margin-l-5" @click="$emit('executeAction', 'addComment', item)">
                                                <el-tooltip content="批注" placement="top">
                                                    <svg-icon name="ib-comment" style="color:#7DD6B3;"></svg-icon>
                                                </el-tooltip>
                                            </div>
                                        </template>
                                    </div>
                                </div>
                                <div class="center">
                                    <div class="split-content" v-if="sourceMode === 1 && sentId === item.sentId">
                                        <div class="left-input" 
                                            :value="item.sourceText"
                                            contenteditable="true" 
                                            style="ime-mode:disabled" 
                                            @keypress.prevent 
                                            @blur="$emit('executeAction', 'exitSplitMode')"
                                            @input="(e)=>splitContentInputChange(e,item)"
                                            v-html="item.sourceText"></div>
                                        <div class="actions" @mousedown.prevent>
                                            <el-tooltip content="确定拆分" placement="top">
                                                <span @click="$emit('executeAction','submitSplit',item)" :disabled="loadingKey === 'split'">
                                                    <svg-icon name="ib-check-o"></svg-icon>
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip content="退出拆分模式" placement="top">
                                                <span @click="$emit('executeAction', 'exitSplitMode')" :disabled="loadingKey === 'split'">
                                                    <svg-icon name="ib-close"></svg-icon>
                                                </span>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div class="edit-source-content" v-else-if="sourceMode === 2 && sentId === item.sentId">
                                        <div class="left-input" 
                                            contenteditable="true" 
                                            @blur="$emit('executeAction','exitEditSource')"
                                            v-html="item.sourceTaggedText"></div>
                                        <div class="actions" @mousedown.prevent>
                                            <el-tooltip content="保存原文" placement="top">
                                                <span @click="e=>$emit('executeAction','saveEditSource',{e,index,item})" :disabled="loadingKey === 'editSource'">
                                                    <svg-icon name="ib-check-o"></svg-icon>
                                                </span>
                                            </el-tooltip>
                                            <el-tooltip content="退出原文编辑模式" placement="top">
                                                <span @click="$emit('executeAction','exitEditSource')" :disabled="loadingKey === 'editSource'">
                                                    <svg-icon name="ib-close"></svg-icon>
                                                </span>
                                            </el-tooltip>
                                        </div>
                                    </div>

                                    <div class="original-doc" :lang="doc.sourceLang" v-else
                                        :data-doc="item.sourceText"
                                        @click.prevent="e=>sourceTagEvent(e, index, item)"
                                        @mousedown.stop="e=>setMenuActionsOnDown(e,index,item,'source')" 
                                        @mouseup.prevent="e=>setMenuActionsOnUp(e,index,item,'source')" 
                                        v-html="item.sourceTaggedText">
                                    </div>
                                    
                                    <div class="target-doc">
                                        <!-- <div class="interactive-doc">
                                            <span class="input"></span><span class="hint"></span>
                                        </div> -->
                                        <template v-if="item.permission.editable">
                                            <template v-if="item.locked">
                                                <div class="doc" contenteditable="false"  v-html="item.targetTaggedText||''"></div>
                                            </template>
                                            <template v-else>
                                                <div class="doc" :lang="doc.targetLang" tabindex="0" contenteditable="true"  
                                                    :data-id="item.sentId" 
                                                    :placeholder="item.translationText"
                                                    @keydown.right="e=>linePressRightEvent(e,index,item)"
                                                    @keydown.left="e=>linePressLeftEvent(e,index,item)"
                                                    @keydown.tab="e=>linePressTabEvent(e,index,item)"
                                                    @focus="e=>lineFocusEvent(e,index,item)"
                                                    @input="e=>lineInputEvent(e,index,item)" 
                                                    @blur="e=>lineInputBlurEvent(e,index,item)"
                                                    @paste="e=>linePasteEvent(e,index,item)"
                                                    @mousedown="e=>setMenuActionsOnDown(e,index,item,'target')" 
                                                    @mouseup="e=>setMenuActionsOnUp(e,index,item,'target')" 
                                                    v-html="item.targetTaggedText||''">
                                                </div>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <div class="doc" v-html="item.targetTaggedText" v-if="item.targetTaggedText"></div>
                                            <div class="doc" style="color: #999;" v-else>{{item.translationText}}</div>
                                        </template>
                                        <div :class="['word-count', config.RTL_LANGUAGE.indexOf(doc.targetLang)>-1?'left':'']">{{item.customText.length}}</div>
                                    </div>
                                </div>
                                
                                <div class="right">
                                    <div class="qa-flag" @click.prevent="showQAPanel" v-if="item.qa">
                                        <el-tooltip content="查看QA检查结果" placement="top">
                                            <svg-icon name="ib-qa-o" :className="item.qa"></svg-icon>
                                        </el-tooltip>
                                    </div>
                                    <div class="qa-flag" v-else></div>
                                    <div class="confirm-status" v-if="item.editorStatus === 'confirm'">
                                        <i class="el-icon-success"></i>
                                    </div>
                                    <div v-else>
                                        <template v-if="item.permission.editable">
                                            <div class="save-btn" @click="saveBtnEvent(index)" :disabled="loadingKey === 'confirmSent'" v-if="activeItemIndex === index" >
                                                <el-tooltip content="确认（Enter）" placement="top">
                                                    <span>确认</span>
                                                </el-tooltip>
                                            </div>
                                            <template v-else>
                                                <template v-if="item.translationScore !== null && item.translationScore < 5">
                                                    <el-tooltip content="机翻译文疑似有风险" placement="top">
                                                        <div class="risk-translation"></div>
                                                        <svg-icon name="ib-warning-o-1" class="fonts-18 color-warning"></svg-icon>
                                                    </el-tooltip>
                                                </template>
                                            </template>
                                        </template>
                                    </div>
                                </div>
                            </div>
                            <template v-if="item.permission.editable">
                                <div class="mt-content" @click="e => replaceCustomTextEvent(e,index,item)" v-if="doc.interactiveMode === 'IMT' && item.editorStatus !=='confirm'">
                                    <div class="left">
                                        <div class="engine-icon" v-if="item.interactionFlag">
                                            <el-tooltip content="LanguageX提供交互式翻译">
                                                <svg-icon name="ie-lang-x" style="width: 16px;height: 16px;"></svg-icon>
                                            </el-tooltip>
                                        </div>
                                        <div class="engine-icon" v-else>
                                            <el-tooltip :content="doc.engineInfo.name+'翻译'" v-if="doc.engineInfo">
                                                <svg-icon :name="'ie-'+doc.engineInfo.icon" style="width: 16px;height: 16px;"></svg-icon>
                                            </el-tooltip>
                                        </div>
                                    </div>
                                    <div class="right">
                                        <div class="mt-info" :lang="doc.targetLang" style="min-height:20px;padding-right:20px;" v-html="item.translationText||''"></div>
                                    </div>
                                </div>
                            </template>
                            <!-- <template v-if="(doc.permission && doc.permission.editable) && !item.permission.editable">
                                <div class="readonly-flag">
                                    <el-tooltip content="分配给别人的任务，无编辑权限" placement="top">
                                        <svg-icon name="ib-uneditable"></svg-icon>
                                    </el-tooltip>
                                </div>
                            </template> -->
                        </div>
                        
                    </div>
                </template>
            </div>
            <div class="height-full flex flex-justify-center flex-align-center" v-else>
                <el-empty description="暂无数据"></el-empty>
            </div>
            <div class="pagination-content" style="margin-top:0;" v-if="doc.sentences.length > 0 && pagination.total > pagination.pageSize">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="pagination.pageNumber"
                    :page-size="pagination.pageSize"
                    layout="total, prev, pager, next"
                    :total="pagination.total">
                </el-pagination>
            </div>
        </div>
        <template>
            <transition name="quickly-add-term-dilaog" enter-active-class="animated fadeInUp faster" leave-active-class="animated fadeOutUp faster">
                <drag-dialog :title="'快速添加术语'" :top="128" :left="400" @close="closeQuicklyAddTermDialog" v-if="showQuicklyAddTerm">
                    <el-form label-width="80px" size="medium" style="margin-right:20px;">
                      <el-form-item label="原文" required>
                          <el-input ref="quicklyAddTermInputSource" v-model="quicklyAddTermForm.source" clearable placeholder="请选择或输入原文" show-word-limit maxlength="500"></el-input>
                      </el-form-item>
                      <el-form-item label="译文" required>
                          <el-input ref="quicklyAddTermInputTarget" v-model="quicklyAddTermForm.target" clearable placeholder="请选择或输入译文" show-word-limit maxlength="500"></el-input>
                      </el-form-item>
                      <el-form-item label="备注">
                          <el-input ref="quicklyAddTermInputDescription" v-model="quicklyAddTermForm.description" clearable placeholder="请输入备注" show-word-limit maxlength="100"></el-input>
                      </el-form-item>
                    </el-form>
                    <div slot="footer" style="text-ali">
                        <el-button plain  round size="small" @click="closeQuicklyAddTermDialog">取消</el-button>
                        <el-button  round size="small" type="primary" :loading="quicklyAddTermOkBtnLoading" @click="saveQuicklyAddTermEvent">确定</el-button>
                    </div>
                </drag-dialog>
            </transition>
        </template>
        
    </div>
</template>
<script>
import { mapState } from 'vuex';
import $ from 'jquery';
import config from '@/utils/config';
import tools from '@/utils/tools';
import { docApi, termApi } from '@/utils/api';
import PreviewWord from './PreviewWord';
import PreviewExcel from './PreviewExcel';
import PreviewPpt from './PreviewPPT';
import MenuActions from './MenuActions'
import DragDialog from '@/components/DragDialog'
import VScrollAffix from 'v-scroll-affix';
import { Affix } from 'vue-affix';
export default {
    name:'BasicEditer',
    components:{
        Affix,
        VScrollAffix,
        PreviewWord,
        PreviewExcel,
        PreviewPpt,
        MenuActions,
        DragDialog,
    },
    props:{
        doc:{
            type:Object,
            default:{}
        },
        preview:{
            type:Array,
            default:[]
        },
        sentId:{
            type:String,
            default:''
        },
        sourceMode:{
            type: Number,
            default: -1
        },
        pagination:{
            type:Object,
            default:{
                pageSize:200,
                pageNumber:1,
                total:0
            }
        },
        jargons:{
            type:Array,
            default:[]
        },
        loadingKey: {
            type: String,
            default: '',
        }
    },
    data(){
        return{
            config,
            autocompleteList:[],
            targetAutocompleteActiveIndex:0,
            autoSaveTimer:null, //自动保存定时器
            interactiveTranslationTimer:null, //交互式翻译定时器
            translationTextParticipleTimer:null, //右键交互实时分词定时器
            getMTResultIndex:-1,
            activeItemIndex: -1,
            activePreview:'target',
            previewTypeList:[{key:'target',name:'译文'},{key:'all',name:'双语', permiss: 'pro'},{key:'source',name:'原文'}],
            tools,
            showMenuActions:'',
            menuActionsPosition:'',
            mouseSelectedText:'',
            mouseSelectedType:'',
            mouseSelectedRange:null,

            termDbList:[],

            showQuicklyAddTerm:false,
            quicklyAddTermOkBtnLoading:false,
            quicklyAddTermForm:{
                source:'',
                target:'',
                description: '',
                db:''
            },
            rightKeyTimer: null,
            enterSaveFlag: false,
            

        }
    },
    computed:{
        ...mapState({
            userInfo: state => state.user.userData,
            LANGUAGE_DICT: state => state.common.LANGUAGE_DICT,
        })
    },
    methods:{
        getInputHtml(data,type){
            let html = [];
            let list = data.sourceSegments;
            if(type === 'target'){
                list = data.targetSegments;
            }
            if(list && list.length > 0){
                list.map(item => {
                    if(item.bold||item.italic||item.underline!=='NONE'){
                        let style=[];
                        if(item.bold){
                            style.push('bold');
                        }
                        if(item.italic){
                            style.push('italic');
                        }
                        if(item.underline!=='NONE'){
                            style.push('underline');
                        }
                        html.push(`<span class="${style.join(' ')}">${item.text}</span>`);
                    }else{
                        html.push(item.text);
                    }
                });
            }else{
                if(type === 'source'){
                    html.push(data.sourceText);
                }else if(type === 'target'){
                    html.push(data.targetText);
                }
            }
            return html.join('');
        },
        setActiveItemIndex(num){
            this.activeItemIndex = num;
        },
        handleCurrentChange(num){
            this.$emit('pageNumChange',num);
        },
        originalDocInputEvent(e){
            $(e.target).html($(e.target).data('doc'));
        },
        linePressEnterDownEvent(e,index,item){
            // console.log('linePressEnterDownEvent:::', index, item);
            this.enterSaveFlag = true;
            let fillMode = item.fillMode;
            if($(e.target).parents('.target-doc').find('.doc').text().length === 0 && item.translationText) {
                fillMode = 'MT';
            }
            this.$emit('executeAction','updateTargetTextProvider', {index, value: fillMode});
            this.autoSaveSentences(index,'confirm',false, 'MANUAL');
            e.preventDefault();
            return false;
        },
        linePressEnterUpEvent(e,index,item){
            // console.log('linePressEnterUpEvent:::', index, item);
            if(index === this.doc.sentences.length-1){
                
            }else{
                this.$nextTick(() => {
                    let _index = index + 1;
                    while(true){
                        if (_index > (this.doc.sentences.length - 1)){
                            break;
                        }
                        let item = this.doc.sentences[_index];
                        if(item.locked !== true && item.editorStatus !== 'confirm'){
                            $(".editer-main .item[data-sentid=" + item.sentId + "]").click();
                            break;
                        }
                        _index ++;
                    }
                    this.activeItemIndex = _index;
                    this.$emit("setSentId",this.doc.sentences[_index]);
                    tools.setCursorAtTheEnd($('.target-doc .doc')[_index]);
                })
            }
            e.preventDefault();
            return false;
        },
        linePressTabEvent(e,index,item){
            this.replaceCustomTextEvent(e,index,item);
            e.preventDefault();
            return false;
        },
        lineClickEvent(e,index,item){
            let node = $(e.target);
            if(node.parents('.qa-flag').length > 0){
                this.showQAPanel();
            }

            if(item.permission.editable){
                if(!item.locked){
                    this.activeItemIndex = index;
                    
                    if(!node.hasClass('.item')){
                        node = $(e.target).parents('.item');
                    }
                    if(!node.hasClass('active')){
                        this.$emit('getRelationInfo',item);
                        if(!this.editSource){
                            node.find('.target-doc .doc').focus();
                        }
                    }else{

                    }
                    this.$emit("setSentId", item);
                }
            }
        },
        lineFocusEvent(e,index,item){
            let self = this;
            tools.setCursorAtTheEnd(e.target);
            tools.tagPositionChecking($(e.target).parents('.item').find('.target-doc .doc'));
            this.activeItemIndex = index;
            // this.queryTranslationTextParticiple(e,index,item);
            this.$emit('executeAction','loadComment');
            let _html = $(e.target).html();
            let _text = $(e.target).text().replace(/\s/g,' ');

            if(!$(e.target).parents('.item').hasClass('active')){
                this.$emit('getRelationInfo',item);
            }
            if(_text === '' || _text.endsWith(' ') || ['zh','ja'].indexOf(this.doc.targetLang) > -1){
                setTimeout(()=>{
                    self.queryInteractiveTranslation(e,index,item);
                },100);
            }
            
        },
        lineInputBlurEvent(e,index,item){
            let node = $('.editer-main .item.active').find('.target-doc .doc');
            let value = node.text().replace(/\s/g,' ');
            //输入框失去焦点时获取光标位置
            let position = tools.getCursortPosition(e.target);
            this.$emit('setCursortPosition',position);
            this.$emit('clear',e,index,item);

            // 自动保存
            if(!this.enterSaveFlag){
                if(value.length > 0){
                    this.autoSaveSentences(index, item.editorStatus, false, 'AUTOMATIC');
                }
                this.enterSaveFlag = false;
            }
            
        },
        linePasteEvent(e,index,item){
            let text = e.clipboardData.getData('text/plain');
            text = text.replace(/\n|\r/ig,"");
            document.execCommand("insertText", false, text);
            e.preventDefault();
        },
        lineInputEvent(e,index,item){
            //  console.log('lineInputEvent:', new Date().getTime());
            let self = this;
            if(this.doc.interactiveMode === 'IMT'){ //编辑器模式为交互式翻译时
                $(e.target).parents('.item').find('.mt-content .mt-info .suggest').remove();
                $(e.target).parents('.item').find('.mt-content .mt-info .fluctuant').remove();
                let value = $(e.target).text().replace(/\s/g,' ');
                if(value){
                    if(this.interactiveTranslationTimer){
                        clearTimeout(this.interactiveTranslationTimer);
                    }
                    
                    if(['zh','ja'].indexOf(this.doc.targetLang) < 0){
                        if(value.endsWith(' ')){
                            this.interactiveTranslationTimer = setTimeout(()=>{
                                self.queryInteractiveTranslation(e,index,item);
                            },100);
                        }
                    }else{
                        this.interactiveTranslationTimer = setTimeout(()=>{
                            self.queryInteractiveTranslation(e,index,item);
                        },500);
                    } 
                }else{
                    setTimeout(()=>{
                        self.queryInteractiveTranslation(e,index,item);
                    },100);
                }
            }
           
            //设置译文类型
            this.$emit('executeAction','updateTargetTextProvider', {index, value: null});
            // 自动保存
            if(this.autoSaveTimer){
                clearTimeout(this.autoSaveTimer);
            }
            /* this.autoSaveTimer = setTimeout(()=>{
                self.autoSaveSentences(index,'non-confirm',false, 'AUTOMATIC');
            },500); */
        },
        linePressLeftEvent(e,index,item){ //
            if (config.RTL_LANGUAGE.indexOf(this.doc.targetLang) > -1) {
                this.fillSuggestWordInteractiveTranslation(e,index,item);
            }
        },
        linePressRightEvent(e,index,item){
            if (config.RTL_LANGUAGE.indexOf(this.doc.targetLang) < 0) {
                this.fillSuggestWordInteractiveTranslation(e,index,item);
            }
        },
        /**交互式翻译填充建议词 */
        fillSuggestWordInteractiveTranslation(e,index,item){
            let self = this;
            let value = $(e.target).text().replace(/\s/g,' ');
            let suggestNode = $(e.target).parents(".item").find(".mt-content .mt-info").find('.suggest');
            let offset = tools.getCursortPosition(e.target)
            if (offset === value.length && suggestNode.length>0){
                let suggestValue = suggestNode.text();
                let rst = value + suggestValue;
                if (['zh','ja'].indexOf(this.doc.targetLang) < 0) {
                    if (!suggestValue.endsWith('.')){
                        rst = rst + ' '; //&nbsp;
                    }
                }
                this.doc.sentences[index].customText = rst;
                let node=$(e.target).parents('.target-doc').find('.doc');
                node.html(rst);
                $(e.target).parents(".item").find(".mt-content .mt-info").find('.suggest').removeClass('suggest');

                if (['en', 'zh'].indexOf(this.doc.targetLang) > -1){
                    this.setInteractiveTranslationHtml(e,index,item);
                }else{
                    this.queryInteractiveTranslation(e,index,item);
                }

                tools.setCursorAtTheEnd(node[0]);

                if (this.rightKeyTimer){
                    clearTimeout(this.rightKeyTimer);
                }
                this.$emit('executeAction','updateTargetTextProvider', {index, value: null});
                this.rightKeyTimer = setTimeout(()=>{
                    self.autoSaveSentences(index,'non-confirm',false,'AUTOMATIC');
                },500);
            }
        },
        /**查询交互式翻译结果及分词 */
        queryInteractiveTranslation(e,index,item){
            if(this.doc.splitMode === 'PARAGRAPH') return;
            let node = $('.editer-main .item.active').find('.target-doc .doc');
            let value = node.text().replace(/\s/g,' ');
            let url =docApi.queryInteractionTranslation;
            let postData={
                source:$('<span>'+item.sourceText+'</span>').text().replace(/\s/g,' '),
                segment:value,
                target: item.translationText,
                sourceLang:this.doc.sourceLang,
                targetLang:this.doc.targetLang,
                terms:this.jargons,
            };
            
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    let data = res.data;
                    this.$set(this.doc.sentences[index], 'participles', data.toks);
                    node.parents('.item').find(".mt-content").data('target', data.target);
                    //设置交互式翻译提示
                    this.$set(this.doc.sentences[index], 'interactionFlag', true);
                    this.setInteractiveTranslationHtml(e,index,item);
                }
            })
        },
        /**设置交互式翻译提示 */
        setInteractiveTranslationHtml(e,index,item){
            let node = $(e.target).parents('.item');
            let value = node.find('.target-doc .doc').text().replace(/\s/g,' ');
            let toks = item.participles;
            let target = node.find(".mt-content").data('target');
            let _html = ''
            if(toks.length > 0){
                let half = target.replace(value,"");
                if (value.length > 0){
                    _html = `<span class="input">${value}</span>`;
                }
                _html += `<span class="suggest">${toks[0]}</span><span class="fluctuant">${half.substring(toks[0].length)}</span>`;
            }else{
                _html = `<span class="input">${target}</span>`;
            }
            node.find(".mt-content .mt-info").html(_html);

            //不连续请求
            if(['en', 'zh'].indexOf(this.doc.targetLang) > -1){
                toks.splice(0, 1);
                this.doc.sentences[index]['participles'] = toks;
            }
        },
        saveBtnEvent(index) {
            let item = this.doc.sentences[index];
            let fillMode = item.fillMode;
            if($('.editer-main .item.active').find('.target-doc .doc').text().length === 0 && item.translationText) {
                fillMode = 'MT';
            }
            this.$emit('executeAction','updateTargetTextProvider', {index, value: fillMode});
            this.autoSaveSentences(index,'confirm',false, 'MANUAL');
        },
        /**自动保存 */
        autoSaveSentences(index, status, isFocus, type){
            let _status = status;
            if(_status === 'confirm'){
                clearTimeout(this.autoSaveTimer);
            }else{
                _status = this.doc.sentences[index].editorStatus; //保留状态
            }
            if(!type) type = 'AUTOMATIC';
            // let _status = this.doc.sentences[index].editorStatus; //保留状态
            this.$emit('saveSentences', index, _status, isFocus, type);
        },
        /**分词算法，中文日文掉分词接口，其他语言按空格分词 */ 
        queryTranslationTextParticiple(e,index,item){
            let value =item.translationText ? item.translationText.replace(/\s/g,' ') : '';
            let lowerTranslationText = value.toLowerCase();
            let lowerText = $(e.target).text().toLowerCase().replace(/\s/g,' ');
            
            if (lowerText.length > 0) {
                if (lowerTranslationText.indexOf(lowerText) !== -1) {
                    value = item.translationText ? item.translationText.substring(lowerText.length) : '';
                }else{
                    value = '';
                }
            }
            if(value){
                if(['zh','ja'].indexOf(this.doc.targetLang) > -1){ //中日掉接口分词 
                    let url = docApi.querySentencesParticiple;
                    let postData = {
                        text: value,
                        lang: this.doc.targetLang
                    }
                    this.$ajax.post(url,postData).then(res=>{
                        if(res.status === 200){
                            let rst = res.data;
                            this.doc.sentences[index]['participles'] = rst;
                        }
                    });
                }else{ //其他语种空格分词
                    let rst = value.split(' ').filter(item => item !== '' && item);
                    this.doc.sentences[index]['participles'] = rst;
                }
            }else{
                this.doc.sentences[index]['participles'] = [];
            }
        },
        getMTResult(e,index,item){
            let node = $('.editer-main .item.active').find('.target-doc .doc');
            let _index = $('.editer-main .item.active').data('docindex');
            let _item = this.doc.sentences[index];
            if (e) {
                node = $(e.target).parents('.item').find('.target-doc .doc');
                _index = index;
                _item = item;
            }
            this.$emit('executeAction','updateTargetTextProvider', {index, value: 'MT'});
            if(_item.translationText){
                node.html(_item.translationText);
                this.autoSaveSentences(index,'non-confirm',false, 'AUTOMATIC');
            }else{
                // console.log('xxx:::', this.doc);
                let url = docApi.translationSentence;
                let postData={
                    sentId: _item.sentId,
                    domain:this.doc.domain,
                    engine:this.doc.engine,
                    sourceLang:this.doc.sourceLang,
                    sourceText:_item.sourceText,
                    targetLang:this.doc.targetLang
                };
                if (this.doc.engine === 'robot'){
                    postData.modelId = this.doc.engineInfo.key.split('@')[1];
                }
                this.getMTResultIndex = _index;
                this.$ajax.post(url,postData).then(res=>{
                    if(res.status === 200){
                        node.html(res.data||'');
                        this.autoSaveSentences(index,'non-confirm',false, 'AUTOMATIC');
                    }
                }).finally(()=>{
                    this.getMTResultIndex = -1;
                })
            }
        },
        /**标记机翻内容区域样式 */
        initTargetTranslationText(e,index,item){
            let text = item.translationText;
            if(item.interactionFlag){
                text = $(e.target).parents('.item').find('.mt-content').data('target');
            }
            let rst = '<span style="color: #6666664D;">'+text+'</span>';
            $(e.target).parents('.item').find('.mt-info').html(rst);
        },
        replaceCustomTextEvent(e,index,item){
            let text = item.translationText;
            if(item.interactionFlag){
                text = $(e.target).parents('.item').find('.mt-content').data('target');
            }
            $(e.target).parents('.item').find(".target-doc .doc").html(text);
            this.initTargetTranslationText(e,index,item);
            this.$emit('executeAction','updateTargetTextProvider', {index, value: 'MT'});
            this.autoSaveSentences(index,'non-confirm',true, 'AUTOMATIC');
        },
        showQAPanel(){
            
            this.$emit('setQAPanel');
        },

        sourceTagEvent(e, index, item) {
            // return;
            
            let node = $(e.target);
            if (!node.is('div[tag="true"]')) {
                node = node.parents('div[tag="true"]');
            }
            if (node.length > 0) {
                let editerNode = node.parents('.doc-content').find('.target-doc .doc');
                var sel = window.getSelection();
                if (!sel.rangeCount) return; 
                var range = sel.getRangeAt(0);
                console.log('range:',  $(range.commonAncestorContainer).parents('.item').data('sentid'), 'item:', node.parents('.item').data('sentid'));
                if(!($(range.commonAncestorContainer).hasClass('doc') || $(range.commonAncestorContainer).parents('.doc').length > 0)) {
                    this.$message.error('光标必须在译文输入框中');
                    return;
                }
                if($(range.commonAncestorContainer).parents('.item').data('sentid') !== node.parents('.item').data('sentid')) {
                    this.$message.error('当前复制的原文标签和译文输入框不匹配');
                    return;
                }
                if(!editerNode.text().trim()) {
                    this.$message.error('译文不可为空');
                    return;
                }
                var clonedTag = node.clone();
                range.deleteContents(); 
                var frag = document.createDocumentFragment();
                var newElement = clonedTag.get(0);
                frag.appendChild(newElement);
                range.insertNode(frag);
                range.setStartAfter(newElement);
                range.collapse(true);

                sel.removeAllRanges();
                sel.addRange(range);
                
                tools.tagPositionChecking(editerNode);
            }
        },
        setMenuActionsOnDown(e,index,item,type){
            this.mouseSelectedType = type;
            if(!($(e.target).hasClass('original-doc')||$(e.target).hasClass('doc'))){
                e.preventDefault();
            }
        },
        /**
         * 设置菜单按钮显示和隐藏，及选中值记录
         */
        setMenuActionsOnUp(e,index,item,type){
            let text = '';
            let range = null;
            if(window.getSelection){
                range = window.getSelection();
                text = window.getSelection().toString();
            }else{
                range = document.selection.createRange();
                text = document.selection.createRange().text;
            }
            this.mouseSelectedRange = {
                anchorOffset:range.anchorOffset,
                baseOffset:range.baseOffset,
                extentOffset: range.extentOffset,
                focusOffset: range.focusOffset,
                isCollapsed: range.isCollapsed,
                rangeCount: range.rangeCount,
                type: range.type,
            }
            this.mouseSelectedText = text;

            //快速添加术语
            if(this.mouseSelectedType === 'source'){
                this.quicklyAddTermForm.source = text.length > 50? text.substring(0,50): text;
            }else if(this.mouseSelectedType === 'target'){
                this.quicklyAddTermForm.target = text.length > 50? text.substring(0,50): text;
            }
            this.menuActionsPosition = this.mouseSelectedType;
            if(text && !item.locked){
                this.showMenuActions = item.sentId;
            }else{
                this.showMenuActions = '';
            }
        },
        closeQuicklyAddTermDialog(){
            this.showQuicklyAddTerm = false;
            this.quicklyAddTermForm={
                source:'',
                target:'',
                description: '',
                db:this.doc.customTermId
            }
        },
        
        menuBtnClickEvent(key){
            switch(key){
                case 'term':
                    this.showMenuActions = false;
                    this.showQuicklyAddTerm = true;
                    // this.initTermsList();
                    this.$nextTick(()=>{
                        if (this.mouseSelectedType === 'source'){
                            this.$refs.quicklyAddTermInputTarget.focus();
                        }else if(this.mouseSelectedType === 'target'){
                            this.$refs.quicklyAddTermInputSource.focus();
                        }
                    });
                    break;
                case 'searchMemory':
                    this.showMenuActions = false;
                    this.$emit('executeAction','searchMemory',this.mouseSelectedText);
                    break;
                case 'addComment':
                    this.showMenuActions = false;
                    this.$emit('executeAction',key);
                    break;
                case 'bold':
                case 'italic':
                case 'underline':
                case 'superscript':
                case 'subscript':
                case 'strikeThrough':
                    this.setTextStyle(key)
                    break;
            }
        },


        /**
         * 查询术语库列表
         */
        initTermsList(){
            let url = termApi.queryTermDBList;//}?pageNumber=1&pageSize=1000` +
                        //`&sourceLang=${this.doc.sourceLang}&targetLang=${this.doc.targetLang}`;
            let postData = {
                sourceLang:this.doc.sourceLang,
                targetLang:this.doc.targetLang,
                projectId:this.doc.projectId
            }
            this.$ajax.post(url,postData).then(res=>{
                if(res.status === 200){
                    this.termDbList = res.data;
                    this.quicklyAddTermForm.db = res.data[0].termId;
                }
            });
        },
        /**
         * 保存快速添加术语
         */
        saveQuicklyAddTermEvent(){
            if(!this.quicklyAddTermForm.source.trim()){
                this.$message.error('术语原文不能为空');
                return;
            }
            if(!this.quicklyAddTermForm.target.trim()){
                this.$message.error('术语译文不能为空');
                return;
            }
            /* if(!this.quicklyAddTermForm.db){
                this.$message.error('请选择术语库');
                return;
            } */
            let url = termApi.saveAddCustomTerm;
            let postData={
                documentId: this.doc.docId,
                source:this.quicklyAddTermForm.source.trim(),
                target:this.quicklyAddTermForm.target.trim(),
                description: this.quicklyAddTermForm.description.trim(),
            }
            this.quicklyAddTermOkBtnLoading = true;
            this.$ajax.post(url, postData).then(res=>{
                if(res.status === 200){
                    this.$message.success("保存术语成功");
                    this.closeQuicklyAddTermDialog();
                }
            }).finally(()=>{
                this.quicklyAddTermOkBtnLoading = false;
            })
        },
        /**
         * 设置句段锁状态
         */
        changeItemLockStatusEvent(index,locked){
            this.$emit('onLocked',index,locked);
        },
        /**
         * 设置文本样式
         */
        setTextStyle(key){
            this.$emit("setStyle", key);
        },
        splitContentInputChange(e,item){
            $(e.target).text(item.sourceText)
        },
        previewTypeChange(item){
            if(item.permiss === 'pro' && ['personal', 'pro-limited'].indexOf(this.userInfo.accountType)>-1){
                this.$router.push('/account/vip');
                // this.$store.dispatch('pay/setPayDialogAction',{show:true});
                return;
            }
            this.activePreview = item.key
        },
        getSentSourceText(item){
            let rst = [];
            if(item.repeated){
                rst.push('重复句段');
            }
            if(item.fillMode === 'TM'){
                rst.push('记忆库');
            }else if(item.fillMode === 'MT'){
                rst.push('机器翻译');
            }else if(item.fillMode === 'NT'){
                rst.push('非译元素');
            }
            let str = '';
            if(rst.length > 0){
                str = `来源于${rst.join("，")}`
            }
            return str;
        },
        bindTargetDocEvent(){
            const self = this;
            $(".editer-main").delegate('.target-doc', 'keydown', function(e){
                const node = $(e.target).parents('.item');
                const index = node.attr('data-docindex') * 1;
                const item = self.doc.sentences[index];
                const key = e.key;
                switch (key) {
                    case 'Enter':
                        // console.log('delegate keydown:::', e);
                        self.linePressEnterDownEvent(e, index, item);
                        break;
                }
                
            });
            $(".editer-main").delegate('.target-doc', 'blur', function(e){
                const node = $(e.target).parents('.item');
                const index = node.attr('data-docindex') * 1;
                const item = self.doc.sentences[index];
                const key = e.key;
                self.$emit('clear',e,index,item);               
            });
            $(".editer-main").delegate('.target-doc', 'keyup', function(e){
                const node = $(e.target).parents('.item');
                const index = node.attr('data-docindex') * 1;
                const item = self.doc.sentences[index];
                const key = e.key;
                switch (key) {
                    case 'Enter':
                        // console.log('delegate keyup:::', e);
                        self.linePressEnterUpEvent(e, index, item);
                        break;
                }
            });
        },
    },
    mounted(){
        let self = this;
        this.bindTargetDocEvent();
        this.$shortcut.bind('alt+f3, ⌘+f3', ()=>{
            this.menuBtnClickEvent('searchMemory');
        });
        this.$shortcut.bind('alt+t', ()=>{
            self.menuBtnClickEvent('term');
        });
        $(document).on('mousedown',e=>{
            if($(e.target).parents('.menu-actions-compontent').length === 0){
                self.showMenuActions = false;
            }
        });
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/variables.scss';
.basic-editer-component{
    display: flex;
    flex: 1;
    min-height: 0;
    // position:relative;
    > .preview-main{
        flex:1;
        border-right: 1px solid #F1F1F1;
        box-sizing: border-box;
        overflow-y: auto;
    }
    .preview-type-list{
        position:absolute;
        margin-top: 14px;
        display: flex;
        flex-direction: column;
        font-size: 12px;
        line-height: 17px;
        color: #00000066;
        .item{
            display: flex;
            align-items: center;
            width: 35px;
            height: 24px;
            margin-bottom: 10px;
            padding-left: 5px;
            cursor: pointer;
            border-radius: 0 20px 20px 0;
            background-color: #ffffff;
            transition: background-color .2s linear;
            user-select: none;
            box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
            &.active{
                color: #ffffff;
                background-color: #7DD6B3;
            }
        }
    }
    > .editer-main{
        flex:1;
        overflow-y: auto;
        &.edit-font-13{
            > .editer-content > .item > .editer{
                .original-doc{
                    font-size: 13px;
                    line-height: 21px;
                    i {
                        font-size: 13px;
                    }
                }
                .target-doc{
                    .interactive-doc, .doc{
                        font-size: 13px;
                        line-height: 21px;
                        i {
                            font-size: 13px;
                        }
                    }
                }
            }
        }
        &.edit-font-14{
            > .editer-content > .item > .editer{
                .original-doc{
                    font-size: 14px;
                    line-height: 22px;
                    i {
                        font-size: 14px;
                    }
                }
                .target-doc{
                    .interactive-doc, .doc{
                        font-size: 14px;
                        line-height: 22px;
                        i {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
        &.edit-font-15{
            > .editer-content > .item > .editer{
                .original-doc{
                    font-size: 15px;
                    line-height: 24px;
                    i {
                        font-size: 15px;
                    }
                }
                .target-doc{
                    .interactive-doc, .doc{
                        font-size: 15px;
                        line-height: 24px;
                        i {
                            font-size: 15px;
                        }
                    }
                }
            }
        }
        &.edit-font-16{
            > .editer-content > .item > .editer{
                .original-doc{
                    font-size: 16px;
                    line-height: 26px;
                    i {
                        font-size: 16px;
                    }
                }
                .target-doc{
                    .interactive-doc, .doc{
                        font-size: 16px;
                        line-height: 26px;
                        i {
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        &.edit-font-17{
            > .editer-content > .item > .editer{
                .original-doc{
                    font-size: 17px;
                    line-height: 28px;
                    i {
                        font-size: 17px;
                    }
                }
                .target-doc{
                    .interactive-doc, .doc{
                        font-size: 17px;
                        line-height: 28px;
                        i {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
        &.edit-font-18{
            > .editer-content > .item > .editer{
                .original-doc{
                    font-size: 18px;
                    line-height: 30px;
                    i {
                        font-size: 18px;
                    }
                }
                .target-doc{
                    .interactive-doc, .doc{
                        font-size: 18px;
                        line-height: 30px;
                        i {
                            font-size: 18px;
                        }
                    }
                }
            }
        }
        > .editer-content{
            // margin-right:20px;
            min-height: 200px;
            > .item{
                display: flex;
                // min-width:0;
                position: relative;
                // user-select: none;
                > .qa-block{
                    position: absolute;
                    top: 0;
                    right: 0;
                    overflow: hidden;
                    cursor: pointer;
                    text-align: right;
                    > i{
                        font-size: 20px;
                    }
                }
                > .editer{
                    flex:1;
                    position: relative;
                    border: 1px solid #0000001A;
                    border-right-color:#ffffff; 
                    border-left-color:#ffffff;
                    border-top-color: #ffffff;
                    box-sizing: border-box;
                    min-width:0;
                    word-wrap: break-word;
                    
                    &::first-child{
                        border-top: 1px solid #0000001A;
                    }
                    .readonly-flag {
                        position: absolute;
                        top: 10px;
                        right: 15px;
                        font-size: 20px;
                    }
                    .original-doc{
                        padding-top: 10px;
                        font-size: 14px;
                        line-height: 22px;
                        // letter-spacing: 0.8px;
                        color: #000000;
                        word-wrap:break-word;
                        word-break: normal;
                        // table-layout: fixed;
                        outline: none;
                        i {
                            font-size: 14px;
                            width: 8px;
                        }
                        &:focus{
                            border-bottom: 1px solid #7DD6B3;
                            box-sizing: border-box;
                        }
                    }
                    .edit-source-content,
                    .split-content{
                        display: none; 
                        .left-input{
                            outline: none;
                            padding-top: 10px;
                            font-size: 14px;
                            line-height: 22px;
                            // letter-spacing: 0.8px;
                            color: #000000;
                            word-wrap:break-word;
                            word-break: normal;
                            table-layout: fixed;
                            flex: 1;
                        }
                        .actions{
                            padding-top: 10px;
                            line-height: 22px;
                            span{
                                cursor: pointer;
                                padding: 5px;
                                border-radius: 4px;
                                &:hover{
                                    background: #0000001A;
                                }
                                &[disabled],
                                &:disabled{
                                    opacity: .3;
                                    pointer-events: none;
                                    user-select: none;
                                }
                            }
                        }
                    }
                    .target-doc{
                        margin-right: 15px;
                        position: relative;
                        .interactive-doc{
                            position: absolute;
                            top: 0;
                            left: 0;
                            // bottom: 0;
                            padding: 10px 0;
                            font-size: 14px;
                            line-height: 22px;
                            word-wrap:break-word;
                            word-break:normal;
                            width: 100%;
                            .input{
                                color: transparent;
                            }
                            .hint{
                                color: #888888;
                                ::v-deep .suggest{
                                    background-color: #ECF4F4;
                                    border-radius: 50px;
                                    padding: 0 4px;
                                }
                            }
                        }
                        .doc{
                            position: relative;
                            z-index: 10;
                            padding: 10px 0;
                            outline: none;
                            font-size: 14px;
                            line-height: 22px;
                            // letter-spacing: 0.8px;
                            word-wrap:break-word;
                            word-break:normal;
                            // table-layout: fixed;
                            color: #000000;
                            &[contenteditable]:empty:before{
                                content: attr(placeholder);
                                color: #00000066;
                            }
                            &[contenteditable]:empty:focus:before{
                                content: attr(placeholder);
                                color: transparent;
                            }
                            &[contenteditable]:focus{
                                content:" ";
                            }
                            i {
                                font-size: 14px;
                            }
                        }
                        > .word-count{
                            display: none;
                            position: absolute;
                            right: -10px;
                            bottom: -3px;
                            font-size: 12px;
                            line-height: 17px;
                            color: #00000080;
                            &.left{
                                left: 0;
                            }
                        }

                    }
                    > .doc-content{
                        display: flex;
                        > .left{
                            width: 60px;
                            display: flex;
                            flex-direction: column;
                            > .line-num{
                                margin-top: 15px;
                                font-size: 14px;
                                color: #00000080;
                                text-align: center;
                            }
                            > .comment{
                                text-align: center;
                                margin-top: 12px;
                                cursor: pointer;
                                padding-top: 6px;
                                svg{
                                    font-size: 16px;
                                }
                            }
                        }
                        > .center{
                            flex: 1;
                            word-wrap: break-word;
                            min-width: 0;
                            
                        }
                        > .right{
                            width: 50px;
                            display: flex;
                            flex-direction: column;
                            position: relative;
                            
                            > div{
                                flex: 1;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                            > .qa-flag{
                                border-radius: 50%;
                                cursor: pointer;
                                /* position: absolute;
                                top: 5px;
                                right: 15px; */
                                font-size: 20px;
                                > .info{
                                    color: $info-color;
                                }
                                > .warn{
                                    color: $warn-color;
                                }
                                > .error{
                                    color: $error-color;
                                }
                            }
                            > .confirm-status{
                                display: flex;
                                justify-content: center;
                                align-items: flex-start;
                                font-size: 20px;
                                color: #7DD6B3;
                            }
                            .save-btn{
                                width: 100%;
                                height: 24px;
                                line-height: 24px;
                                font-size: 12px;
                                text-align: center;
                                cursor: pointer;
                                color: #ffffff;
                                background-color: #7DD6B3;
                                border-radius: 20px 0 0 20px;
                                margin-bottom: 10%;
                                &[disabled],
                                &:disabled{
                                    opacity: .3;
                                    pointer-events: none;
                                    user-select: none;
                                }
                            }
                        }
                    }
                    > .mt-content{
                        display: none;
                        > .left{
                            width: 60px;
                            text-align: center;
                            > .engine-icon{
                                padding-top: 14px;
                                > i{
                                    font-size: 14px;
                                }
                            }
                        }
                        > .right{
                            padding: 10px 0;
                            border-top: 1px dashed #0000001A;
                            box-sizing: border-box;
                            flex: 1;
                            font-size: 14px;
                            line-height: 20px;
                            color: #00000080;
                            cursor: pointer;
                            .mt-info{
                                ::v-deep .input{
                                    color: #6666664D;
                                }
                                ::v-deep .suggest{
                                    background-color: #D8F3E8;
                                    border-radius: 50px;
                                    padding: 0 4px;
                                }
                            }
                        }
                    }
                }
                > .not-editable-cover{
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    z-index: 10;
                    background-color: #ffffffd2;
                    
                    display: flex;
                    
                    align-items: center;
                    justify-content: center;
                    svg {
                        color: #000000;
                        font-size: 20px;
                        cursor: pointer;
                    }
                    > .left{
                        width: 60px;
                        height: 100%;
                        display: flex;
                        flex-direction: column;
                        > div{
                            flex: 1;
                            text-align: center;
                            cursor: pointer;
                            // padding-top: 8px;
                            font-size: 16px;
                            color: #000;
                            
                        }
                    }
                    > .right{
                        flex: 1;
                    }
                }
                &.active{
                    user-select: auto;
                    > .editer{
                        border: 2px solid #7DD6B3;
                        box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
                        .target-doc{
                            .word-count{
                                display: block;
                            }
                        }
                        .edit-source-content,
                        .split-content{
                           display: flex; 
                        }
                        > .mt-content{
                            display: flex;
                        }
                    }
                    &:hover{
                         > .editer{
                            border: 2px solid #7DD6B3;
                            box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
                            background-color: inherit;
                         }
                    }
                    
                }
                &:hover{
                     > .editer{
                         border: 1px solid #7DD6B3;
                         background-color: #e6f9f1;
                     }
                }
                &.disabled{
                    > .editer{
                        background-color: #f2f2f2;
                        opacity: .45;
                    }
                    &:hover {
                        > .editer{
                            border-color: #ffffff;
                            border-bottom-color: #0000001A;
                            background-color: #f2f2f2;
                        }
                    }
                }
                > .actions{
                    width: 47px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    > div{
                        margin-top: 10px;
                        cursor: pointer;
                    }
                    i{
                        font-size: 20px;
                    }
                }
            }
        }
        > .pagination-content{
            padding: 20px 0;
            text-align: center;
        }
        
    }
    .risk-translation{
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #DE0000;
        &:hover{
            width: 12px;
            height: 12px;
        }
    }
}
</style>
