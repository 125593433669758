<template>
    <div class="find-replace-right-components">
        <div class="find-replace-panel">
            <div class="panel-title">
                <div class="title"><svg-icon name="ib-search"></svg-icon> 查找替换</div>
                <div class="close" @click="closeQaPanel">
                    <el-tooltip content="关闭" placement="top">
                        <svg-icon name="ib-close"></svg-icon>
                    </el-tooltip>
                </div>
            </div>
            <div class="form-content">
                <div class="input-item">
                    <div class="input-label">
                        <span>查找</span>
                    </div>
                    <div class="input-edit">
                        <el-input placeholder="输入查找的原文或译文" 
                            clearable 
                            suffix-icon="el-icon-search" 
                            @keyup.enter.native="searchBtnEvent" 
                            @input="inputChangeEvent" 
                            v-model="form.searchInputValue"></el-input>
                    </div>
                </div>
                <div class="input-item">
                    <div class="input-label"><span>替换为</span></div>
                    <div class="input-edit">
                        <el-input placeholder="输入替换的内容，只能替换译文" 
                            clearable 
                            @keyup.enter.native="searchBtnEvent" 
                            @input="inputChangeEvent" 
                            v-model="form.replaceInputValue"></el-input>
                    </div>
                </div>
                <div class="input-actions flex flex-align-center flex-justify-between">
                    <div class="input-check">
                        <el-checkbox @change="caseSensitiveChangeEvent">Aa区分大小写</el-checkbox>
                    </div>
                    <div class="input-btns">
                        <el-button type="primary" round size="mini" 
                            :loading="loading" 
                            @click="searchBtnEvent">查找</el-button>
                        <el-button plain round size="mini" 
                            :loading="replaceAllLoading" 
                            @click="replaceAllBtnEvent" 
                            :disabled="!canReplaceAll">全部替换</el-button>
                        <el-button plain round size="mini" :loading="repealReplaceAllBtnLoading" v-if="showRepealBtn" @click="repealReplaceAllBtnEvent">撤销替换</el-button>
                    </div>
                </div>
            </div>
            <div class="result-content" v-loading="loading">
                <div class="result-list" v-if="list.length>0">
                    <template v-for="(item,index) in list">
                        <div class="item" :key="index">
                            <div class="action-content">
                                <div class="line-num">
                                    <el-tooltip content="点击定位" placement="top">
                                        <span @click="locateBtnEvent(item)"><svg-icon name="ib-location"></svg-icon> 句段{{item.serialNo}}</span>
                                    </el-tooltip>
                                </div>
                            </div>
                            <div class="source-content" :lang="doc.sourceLang">
                                <template v-for="(s,i) in item.source">
                                    <span :class="[s.active?'find-kw':'']" :key="i">{{s.text}}</span>
                                </template>
                            </div>
                            <div class="target-content" :lang="doc.targetLang">
                                <template v-for="(t,i) in item.target">
                                    <template v-if="t.active">
                                        <span :class="[form.replaceInputValue?'find-replace-kw':'find-kw']" :key="i">{{t.text}}</span>
                                        <template v-if="form.replaceInputValue">
                                            <el-tooltip content="点击替换" placement="top" :key="i+'-r'">
                                                <span class="replace-kw" @click="replaceBtnEvent(index,i,item,t)">{{form.replaceInputValue}}</span>
                                            </el-tooltip>
                                        </template>
                                    </template>
                                    <span :key="i" v-else>{{t.text}}</span>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="none-block" v-else>暂无内容</div>
            </div>
        </div>
    </div>
</template>
<script>
import $ from 'jquery';
export default {
    name:"QARight",
    props:{
        doc:{
            type: Object,
            default: {}
        },
        list:{
            type:Array,
            default:[]
        },
        loading:{
            type:Boolean,
            default:false
        },
        replaceLoading:{
            type:Boolean,
            default:false
        },
        replaceAllLoading:{
            type:Boolean,
            default:false
        },
        showRepealBtn:{
            type:Boolean,
            default:false
        },
        canReplaceAll:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return {
            form:{
                // searchType:'source',
                searchInputValue:'',
                replaceInputValue:'',
                caseSensitive:false,
            },
            replaceIndex:-1,
            repealReplaceAllBtnLoading:false,
            searchTimer:null,
        }
    },
    methods:{
        closeQaPanel(){
            this.$emit('close');
        },
        inputChangeEvent(){
            let self = this;
            if(this.searchTimer){
                clearTimeout(this.searchTimer);
            }
            this.searchTimer = setTimeout(()=>{
                self.searchBtnEvent();
            },500);
        },
        caseSensitiveChangeEvent(val){
            this.form.caseSensitive = val;
            
        },
        searchBtnEvent(){
            if(this.form.searchInputValue){
                this.$emit('search',{...this.form});
            }else{
                this.$emit('clearList');
                // this.$message.error('请输入查找的内容');
            }
        },
        replaceAllBtnEvent(item){
            this.$emit('replaceAll',{...item, ...this.form});
        },
        replaceBtnEvent(index,tIndex,item,target){
            this.replaceIndex = index;
            this.$emit('replace',{sentenceId:item.sentenceId, index, tIndex, startIndex:target.start, endIndex:target.end, ...this.form});
        },
        locateBtnEvent(item){
            this.$emit('locate',{...item, sentId: item.sentenceId});
        },
        repealReplaceAllBtnEvent(){
            let self = this;
            this.$emit('repeal');
            setTimeout(()=>{
                this.repealReplaceAllBtnLoading = true;
            },1000)
        }
    }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/css/variables.scss';
.find-replace-right-components{
    height: 100%;
    width: 380px;
    
    overflow-y: hidden;
    // flex: 1;
    .find-replace-panel{
        display: flex;
        flex-direction: column;
        height: 100%;
        .panel-title{
            margin: 0 20px;
        }
        .form-content{
            margin: 20px 20px 0 20px;
            border-bottom:1px solid #F1F1F1;
            > .input-item{
                height: 40px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 6px;
                display: flex;
                margin-bottom: 10px;
                > .input-label{
                    width: 76px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    color: #000000;
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                    > span{
                        margin-right: 8px;
                    }
                }
                > .input-edit{
                    flex: 1;
                }
                > .clear-btn{
                    width: 24px;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    font-size: 14px;
                    cursor: pointer;
                    margin-right: 10px;
                    color: #00000066;
                    &:hover{
                        color: #000000;
                    }
                }
            }
            .input-check{
                margin-bottom: 20px;
            }
            .input-btns{
                margin-bottom: 20px;
            }
        }
        .result-content{
            flex: 1;
            position: relative;
            .result-list{
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                bottom: 0;
                overflow: auto;
                padding: 10px 20px;
                > .item{
                    // height: 40px;
                    background-color:#FAFAFA;
                    border-radius: 8px; 
                    border: 1px solid #ffffff;
                    box-sizing: border-box;
                    margin-bottom: 8px;
                    padding: 15px 20px;
                    .action-content{
                        display: flex;
                        align-items: center;
                        margin-bottom: 15px;
                        > div{
                            flex: 1;
                            &.line-num{
                                cursor: pointer;
                                font-size: 13px;
                                color: #00000080;
                                svg{
                                    font-size: 14px;
                                }
                            }
                            &.btns{
                                text-align: right;
                                span{
                                    cursor: pointer;
                                }
                                svg{
                                    font-size: 18px;
                                }
                            }
                        }
                    }
                    .source-content{
                        font-size: 14px;
                        line-height: 20px;
                        margin-bottom: 10px;
                        
            
                    }
                    .target-content{
                        font-size: 14px;
                        line-height: 20px;
                    }
                    ::v-deep .find-kw{
                        margin: 0 2px;
                        background-color: #FFF370;
                    }
                    ::v-deep .find-replace-kw{
                        margin: 0 2px;
                        background-color: #FFF370;
                        text-decoration: line-through;
                        
                    }
                    ::v-deep .replace-kw{
                        margin: 0 2px;
                        background-color: #7DD6B3;
                        cursor: pointer;
                    }
                    
                }
            }
            
            .none-block{
                margin-top: 20px;
                text-align: center;
            }
        }
    }
}
</style>
<style lang="scss">
.input-item{
    > .input-edit , > .input-label{
        .el-input__inner{
            border: none;
        }
    }
}
</style>
