var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"preview-excel-component"},[(_vm.preview)?[_c('div',{staticClass:"difference-tips"},[_vm._v("*预览格式可能和下载后的最终格式不同")]),_vm._l((_vm.preview),function(item,index){return [_c('div',{key:`sheet-${_vm.tools.getRandomId(6)}-${index}`,staticClass:"sheet-content"},[_c('div',{staticClass:"sheet-name"},[_vm._v("Sheet"+_vm._s((item.sheetIdx*1)+1))]),[(item.type==='image')?[_c('p',{key:`image-${_vm.tools.getRandomId(6)}-${index}`,staticClass:"image"},[_c('img',{attrs:{"src":item.url ? item.url : ('data:image/jpg;base64,' + item.encode)}})])]:(item.type==='table')?[_c('table',{key:`table-${_vm.tools.getRandomId(6)}-${index}`,staticClass:"preview-table"},[_c('thead'),_c('tbody',_vm._l((item.maxRowIdx+1),function(row){return _c('tr',{key:`row-${_vm.tools.getRandomId(6)}-${row}`,attrs:{"data-key":row}},_vm._l((item.maxColIdx+1),function(col){return _c('td',{key:`col-${_vm.tools.getRandomId(6)}-${col}`},[(item.table[row-1])?[(item.table[row-1][col-1])?[_vm._l((item.table[row-1][col-1]),function(paragraph,pIndex){return [(['all','source'].indexOf(_vm.active) > -1)?_c('p',{key:`paragraph-source-${_vm.tools.getRandomId(6)}-${pIndex}`,style:(paragraph.style),attrs:{"lang":_vm.doc.sourceLang}},[_vm._l((paragraph.sentences),function(sent,sIndex){return [_c('span',{key:`sent-source-${_vm.tools.getRandomId(6)}-${sIndex}`,class:[_vm.sentId === sent.sentId?'active':''],style:({
                                                                    ...sent.style,
                                                                    fontSize:(sent.style.fontSize*1<14?14:sent.style.fontSize)+'px',
                                                                    lineHeight:(parseFloat(sent.style.fontSize*1<14?14:sent.style.fontSize)+_vm.lineHeightAddition)+'px',
                                                                    backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                                                }),attrs:{"data-sentid":sent.sentId},domProps:{"innerHTML":_vm._s(sent.source)}})]})],2):_vm._e(),(['all','target'].indexOf(_vm.active) > -1)?_c('p',{key:`paragraph-target-${_vm.tools.getRandomId(6)}-${pIndex}`,style:({...paragraph.style, textAlign:(_vm.doc.targetLang==='ar' && paragraph.style.textAlign==='left')?'inherit':paragraph.style.textAlign}),attrs:{"lang":_vm.doc.targetLang}},[_vm._l((paragraph.sentences),function(sent,sIndex){return [_c('span',{key:`sent-target-${_vm.tools.getRandomId(6)}-${sIndex}`,class:[_vm.sentId === sent.sentId?'active':''],style:({
                                                                    ...sent.style,
                                                                    fontSize:(sent.style.fontSize*1<14?14:sent.style.fontSize)+'px',
                                                                    lineHeight:(parseFloat(sent.style.fontSize*1<14?14:sent.style.fontSize)+_vm.lineHeightAddition)+'px',
                                                                    backgroundColor:(sent.status === 'confirm'?'#CDF7E6':'')
                                                                }),attrs:{"data-sentid":sent.sentId},domProps:{"innerHTML":_vm._s(sent.target)}})]})],2):_vm._e()]})]:void 0]:void 0],2)}),0)}),0)])]:_vm._e()]],2)]})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }